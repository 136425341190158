import { SaleStatus } from "@/src/sales/types/sales";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./Select";
import { cn } from "../utils/cn";

const statusOptions: SaleStatus[] = [
  "Ingresada",
  "En proceso",
  "A liquidar",
  "Observada",
  "Rechazada",
];

interface StatusFilterProps {
  value?: SaleStatus;
  onValueChange: (value: SaleStatus | undefined) => void;
  className?: string;
}

export function StatusFilter({ value, onValueChange, className }: StatusFilterProps) {
  return (
    <Select value={value ?? ""} onValueChange={(val) => onValueChange(val as SaleStatus)}>
      <SelectTrigger className={cn("min-w-[130px] max-w-[140px]", className)}>
        <SelectValue placeholder="Estado" />
      </SelectTrigger>
      <SelectContent>
        {statusOptions.map((status) => (
          <SelectItem
            className={cn("text-sm", value === status ? "font-bold" : "")}
            key={status}
            value={status}
          >
            {status}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}
