import { SVGProps } from "react";

const FiltroIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2.49805 9.99905H7.97005C8.39933 11.5785 9.3364 12.9728 10.6367 13.9669C11.937 14.961 13.5283 15.4996 15.1651 15.4996C16.8018 15.4996 18.3931 14.961 19.6934 13.9669C20.9937 12.9728 21.9308 11.5785 22.3601 9.99905H46.4981C47.0285 9.99905 47.5372 9.78834 47.9123 9.41327C48.2873 9.03819 48.4981 8.52949 48.4981 7.99905C48.4981 7.46862 48.2873 6.95991 47.9123 6.58484C47.5372 6.20977 47.0285 5.99905 46.4981 5.99905H22.3601C21.9308 4.41959 20.9937 3.02526 19.6934 2.03119C18.3931 1.03712 16.8018 0.498535 15.1651 0.498535C13.5283 0.498535 11.937 1.03712 10.6367 2.03119C9.3364 3.02526 8.39933 4.41959 7.97005 5.99905H2.49805C1.96762 5.99905 1.45891 6.20977 1.08384 6.58484C0.708768 6.95991 0.498055 7.46862 0.498055 7.99905C0.498055 8.52949 0.708768 9.03819 1.08384 9.41327C1.45891 9.78834 1.96762 9.99905 2.49805 9.99905ZM15.1641 4.49905C15.8563 4.49905 16.533 4.70432 17.1086 5.08891C17.6841 5.47349 18.1327 6.02012 18.3976 6.65966C18.6625 7.2992 18.7319 8.00294 18.5968 8.68187C18.4618 9.3608 18.1284 9.98444 17.6389 10.4739C17.1494 10.9634 16.5258 11.2968 15.8469 11.4318C15.1679 11.5668 14.4642 11.4975 13.8247 11.2326C13.1851 10.9677 12.6385 10.5191 12.2539 9.94355C11.8693 9.36798 11.6641 8.69129 11.6641 7.99905C11.6651 7.07112 12.0342 6.1815 12.6904 5.52535C13.3465 4.8692 14.2361 4.50011 15.1641 4.49905Z"
      fill="currentColor"
    />
    <path
      d="M46.4981 22.4971H41.0261C40.5975 20.9173 39.6608 19.5224 38.3606 18.5279C37.0604 17.5335 35.469 16.9946 33.8321 16.9946C32.1951 16.9946 30.6037 17.5335 29.3035 18.5279C28.0033 19.5224 27.0666 20.9173 26.6381 22.4971H2.49806C1.96763 22.4971 1.45892 22.7078 1.08385 23.0829C0.708776 23.4579 0.498062 23.9667 0.498062 24.4971C0.498062 25.0275 0.708776 25.5362 1.08385 25.9113C1.45892 26.2864 1.96763 26.4971 2.49806 26.4971H26.6381C27.0666 28.0769 28.0033 29.4717 29.3035 30.4662C30.6037 31.4607 32.1951 31.9995 33.8321 31.9995C35.469 31.9995 37.0604 31.4607 38.3606 30.4662C39.6608 29.4717 40.5975 28.0769 41.0261 26.4971H46.4981C47.0285 26.4971 47.5372 26.2864 47.9123 25.9113C48.2873 25.5362 48.4981 25.0275 48.4981 24.4971C48.4981 23.9667 48.2873 23.4579 47.9123 23.0829C47.5372 22.7078 47.0285 22.4971 46.4981 22.4971ZM33.8321 27.9971C33.1398 27.9971 32.4631 27.7918 31.8876 27.4072C31.312 27.0226 30.8634 26.476 30.5985 25.8365C30.3336 25.1969 30.2643 24.4932 30.3993 23.8143C30.5344 23.1353 30.8677 22.5117 31.3572 22.0222C31.8467 21.5327 32.4703 21.1994 33.1492 21.0643C33.8282 20.9293 34.5319 20.9986 35.1715 21.2635C35.811 21.5284 36.3576 21.977 36.7422 22.5526C37.1268 23.1282 37.3321 23.8048 37.3321 24.4971C37.331 25.425 36.9619 26.3146 36.3058 26.9708C35.6496 27.6269 34.76 27.996 33.8321 27.9971Z"
      fill="currentColor"
    />
    <path
      d="M46.498 38.9992H22.36C21.9308 37.4197 20.9937 36.0254 19.6934 35.0313C18.3931 34.0372 16.8018 33.4987 15.165 33.4987C13.5283 33.4987 11.937 34.0372 10.6367 35.0313C9.33639 36.0254 8.39932 37.4197 7.97005 38.9992H2.49805C1.96761 38.9992 1.45891 39.2099 1.08383 39.5849C0.708761 39.96 0.498047 40.4687 0.498047 40.9991C0.498047 41.5296 0.708761 42.0383 1.08383 42.4134C1.45891 42.7884 1.96761 42.9991 2.49805 42.9991H7.97005C8.39932 44.5786 9.33639 45.9729 10.6367 46.967C11.937 47.9611 13.5283 48.4996 15.165 48.4996C16.8018 48.4996 18.3931 47.9611 19.6934 46.967C20.9937 45.9729 21.9308 44.5786 22.36 42.9991H46.498C47.0285 42.9991 47.5372 42.7884 47.9123 42.4134C48.2873 42.0383 48.498 41.5296 48.498 40.9991C48.498 40.4687 48.2873 39.96 47.9123 39.5849C47.5372 39.2099 47.0285 38.9992 46.498 38.9992ZM15.164 44.4991C14.4718 44.4991 13.7951 44.2939 13.2196 43.9093C12.644 43.5247 12.1954 42.9781 11.9305 42.3385C11.6656 41.699 11.5963 40.9953 11.7313 40.3163C11.8663 39.6374 12.1997 39.0138 12.6892 38.5243C13.1787 38.0348 13.8023 37.7015 14.4812 37.5664C15.1602 37.4314 15.8639 37.5007 16.5034 37.7656C17.143 38.0305 17.6896 38.4791 18.0742 39.0547C18.4588 39.6302 18.664 40.3069 18.664 40.9991C18.6625 41.9269 18.2932 42.8162 17.6372 43.4723C16.9811 44.1283 16.0918 44.4975 15.164 44.4991Z"
      fill="currentColor"
    />
  </svg>
);

export default FiltroIcon;
