import { flexRender, RowData, Table as TableType } from "@tanstack/react-table";
import { cn } from "../utils/cn";
import ReorderArrowUp from "../icons/reorder-arrow-up";
import ReorderArrowDown from "../icons/reorder-arrow-down";

interface TableProps<T extends RowData> {
  table: TableType<T>;
  className?: string;
}

export default function Table<T extends RowData>({ table, className }: TableProps<T>) {
  return (
    <table
      className={cn(
        "w-full border-collapse border-y border-solid border-y-gray-300 text-base",
        className
      )}
    >
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th
                className="group h-auto min-h-10 cursor-pointer truncate border-y border-gray-300 px-2 py-2 text-primary"
                key={header.id}
                onClick={header.column.getToggleSortingHandler()}
              >
                <div className="flex items-center justify-center gap-2">
                  {flexRender(header.column.columnDef.header, header.getContext())}

                  {header.column.getCanSort() && (
                    <div className="flex">
                      <ReorderArrowUp
                        className="h-4 w-4"
                        fill={header.column.getIsSorted() === "desc" ? "#4B5563" : "#4744cc"}
                      />
                      <ReorderArrowDown
                        className="h-4 w-4"
                        fill={header.column.getIsSorted() === "asc" ? "#4B5563" : "#4744cc"}
                      />
                    </div>
                  )}
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.length === 0 && (
          <tr>
            <td colSpan={table.getAllColumns().length} className="py-6 text-center">
              No se encontraron resultados
            </td>
          </tr>
        )}
        {table.getRowModel().rows.map((row) => (
          <tr className="border-y border-gray-300 text-center font-medium" key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <td className="h-auto min-h-10 overflow-hidden truncate px-2 py-2" key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
