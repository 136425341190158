import React, { useState, useCallback, useRef } from "react";
import { CheckCircle, Upload } from "lucide-react";
import type { FileError } from "../utils/domain";
import { useUploadFileMutation } from "../api";
import { DocumentType } from "../../quotes/components/MembershipForm";
const truncateFilename = (filename: string): string => {
  const maxLength = 10;
  if (filename.length <= maxLength) return filename;

  const extension = filename.split(".").pop() || "";
  const name = filename.substring(0, filename.lastIndexOf("."));

  return `${name.substring(0, maxLength)}...${extension}`;
};

interface FileDropzoneProps {
  documentType: DocumentType;
  onFilesAccepted: (files: File[], documentType: DocumentType) => void;
  onError: (error: FileError) => void;
  maxFiles?: number;
  maxFileSize?: number;
  acceptedFileTypes?: string[];
  error?: FileError;
  entityId: string;
  entityName: string;
}

export const FileDropzone: React.FC<FileDropzoneProps> = ({
  documentType,
  onFilesAccepted,
  onError,
  maxFiles = 1,
  maxFileSize = 1000000,
  acceptedFileTypes = ["application/pdf", "image/jpeg", "image/png"],
  error,
  entityId,
  entityName,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const uploadMutation = useUploadFileMutation({});

  const validateFiles = useCallback(
    (files: File[]): void => {
      if (files.length > maxFiles) {
        throw new Error(
          `Máximo ${maxFiles} archivo${maxFiles === 1 ? "" : "s"} permitido${maxFiles === 1 ? "" : "s"}`
        );
      }

      for (const file of files) {
        if (!acceptedFileTypes.includes(file.type)) {
          throw new Error(`Tipo de archivo no permitido: ${file.type}`);
        }

        if (file.size > maxFileSize) {
          throw new Error(
            `El archivo ${file.name} excede el tamaño máximo de ${maxFileSize / 1000000}MB`
          );
        }
      }
    },
    [maxFiles, acceptedFileTypes, maxFileSize]
  );
  const handleFiles = useCallback(
    async (files: File[]) => {
      try {
        validateFiles(files);
        setIsUploading(true);

        for (const file of files) {
          await uploadMutation.mutateAsync({
            entityid: entityId,
            entityname: entityName,
            doc: file,
          });
        }

        setUploadedFiles(files);
        onFilesAccepted(files, documentType);
      } catch (err) {
        setUploadedFiles([]);
        onError(err as FileError);
      } finally {
        setIsUploading(false);
      }
    },
    [validateFiles, onFilesAccepted, documentType, onError, entityId, entityName, uploadMutation]
  );

  const handleDrag = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === "dragenter" || e.type === "dragover") {
      setIsDragging(true);
    } else if (e.type === "dragleave") {
      setIsDragging(false);
    }
  }, []);

  const handleDrop = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(false);

      const files = Array.from(e.dataTransfer.files);
      handleFiles(files);
    },
    [handleFiles]
  );

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isDragging) {
      fileInputRef.current?.click();
    }
  };

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files = Array.from(e.target.files);
      handleFiles(files);
    }
  };

  const hasUploadedFiles = uploadedFiles.length > 0;

  return (
    <div
      className={`relative flex h-20 w-full cursor-pointer items-center justify-center rounded-lg border-2 border-dashed transition-colors ${
        isDragging
          ? "border-blue-500 bg-blue-50"
          : error
            ? "border-red-500 bg-red-50"
            : hasUploadedFiles
              ? "border-green-500 bg-green-50"
              : "border-gray-300 bg-gray-50"
      } `}
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
      onDragOver={handleDrag}
      onDrop={handleDrop}
      onClick={handleClick}
    >
      <input
        ref={fileInputRef}
        type="file"
        className="hidden"
        accept={acceptedFileTypes.join(",")}
        onChange={handleFileInput}
        multiple={maxFiles > 1}
      />
      <div className="p-2 text-center">
        {hasUploadedFiles ? (
          <>
            <CheckCircle className="mx-auto h-6 w-6 text-green-500" />
            <p className="mt-0.5 text-xs text-green-600">
              {uploadedFiles.map((file) => truncateFilename(file.name)).join(", ")}
            </p>
          </>
        ) : (
          <>
            <Upload className={`mx-auto h-6 w-6 ${error ? "text-red-400" : "text-gray-400"}`} />
            <p className="mt-0.5 text-xs text-gray-600">PDF, JPG, PNG • Máx. 1MB</p>
            {error && <p className="mt-0.5 line-clamp-2 text-xs text-red-600">{error.message}</p>}
          </>
        )}
      </div>
    </div>
  );
};
