import doctoredLogo from "../../common/assets/doctored.png";
import galenoLogo from "../../common/assets/galeno.png";
import omintLogo from "../../common/assets/omint.png";
import premedicLogo from "../../common/assets/premedic.png";
import prevencionLogo from "../../common/assets/prevencion.png";
import sancorLogo from "../../common/assets/sancor.png";

const providersOptions = [
    { value: "doctored", label: "Doctored", image: doctoredLogo },
    { value: "galeno", label: "Galeno", image: galenoLogo },
    { value: "omint", label: "Omint", image: omintLogo },
    { value: "premedic", label: "Premedic", image: premedicLogo },
    { value: "prevencion", label: "Prevención", image: prevencionLogo },
    { value: "sancor", label: "Sancor", image: sancorLogo },
];

function getLogo(nameProvider: string) {
    const logos = {
        DOCTORED: doctoredLogo,
        GALENO: galenoLogo,
        OMINT: omintLogo,
        PREMEDIC: premedicLogo,
        PREVENCION: prevencionLogo,
        SANCOR: sancorLogo,
    };
    return logos[nameProvider as keyof typeof logos] || "";
}



const benefitsOptions = [
    { value: "emergency_room", label: "Guardia" },
    {
        value: "hospitalization",
        label: "Internacion habitacion c o i",
    },
    { value: "dentistry", label: "Odontologia" },
    { value: "orthodontics", label: "Ortodoncia" },
    { value: "office_visit", label: "Atencion en consultorio" },
    { value: "prosthesis", label: "Protesis e implantes POR REINTEGRO" },
    { value: "PMI", label: "Plan materno infantil" },
    { value: "optics", label: "Opticas 100% hasta 15 años" },
    { value: "home_visits", label: "Consultas en Domicilio" },
    { value: "diagnostic_imaging", label: "Diagnostico por Imagen" },
    { value: "laboratory", label: "Laboratorio" },
    { value: "physical_therapy", label: "Kinesiologia" },
    { value: "psychology", label: "Psicologia" },
    { value: "ophthalmology", label: "Oftalmologia" },
    { value: "outpatient_medications", label: "Medicamentos ambulatorios" },
    { value: "cosmetic_surgery", label: "Cirugía Estetica" },
    { value: "bruxism_guard", label: "Placas bucales de bruxismo" },
    { value: "rpg", label: "Reeducación Postural Global" },
    { value: "telemedicine", label: "Telemedicina" },
];
const employTypes = [
    { value: 1, label: "Relación de dependencia" },
    { value: 2, label: "Particular" },
    { value: 3, label: "Monotributista" },
];
const zoneTypes = [
    { value: 1, label: "Zona Norte" },
    { value: 2, label: "Zona Sur" },
    { value: 3, label: "Zona Oeste" },
    { value: 5, label: "CABA" },
    { value: 6, label: "Córdoba" },
    { value: 7, label: "Pilar" },
    { value: 8, label: "Río Cuarto" },
    { value: 9, label: "Río Segundo" },
    { value: 10, label: "Villa María" },
];

const genderTypes = [
    { value: "1", label: "Masculino" },
    { value: "2", label: "Femenino" },
];

const plans = [
    "4500_23S",
    "6500_21S",
    "6500_22S",
    "4500_24S",
    "1500_22S",
    "2500_20S",
    "2500_24S",
    "4021_20S",
    "4021_22S",
    "4500_20S",
    "6500_20S",
    "8500_21S",
    "8500_22S",
    "1500_21S",
    "1500_20S",
    "DOCTORED 1000",
    "DOCTORED 2000",
    "DOCTORED 3000"
];
type HealthProvider = "doctored" | "avalian" | "sancor" | "premedic";

type EmployType = 1 | 2 | 3;

type MemberType = 'titular' | 'couple' | 'son';

interface FileError {
    code: "invalid_type" | "invalid_size" | "max_files" | "required";
    message: string;
}
interface Step {
    id: number;
    title: string;
    description: string;
}
export {
    providersOptions,
    getLogo,
    benefitsOptions,
    employTypes,
    zoneTypes,
    genderTypes,
    plans,
    FileError,
    HealthProvider,
    EmployType,
    MemberType,
    Step
}