import React from "react";

interface ReorderArrowUpIconProps {
  fill?: string;
  className?: string;
  width?: number;
  height?: number;
}

const ReorderArrowUpIcon = ({
  fill = "#4B5563",
  className = "",
  width = 24,
  height = 49,
}: ReorderArrowUpIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M23.4184 13.0822C24.2004 12.3002 24.2004 11.0362 23.4184 10.2542L14.8324 1.66822C14.0524 0.888216 13.0284 0.496216 12.0044 0.496216C10.9804 0.496216 9.95641 0.886216 9.17641 1.66622L0.590406 10.2522C-0.191594 11.0342 -0.191594 12.2982 0.590406 13.0802C1.37241 13.8622 2.63641 13.8622 3.41841 13.0802L10.0044 6.49422V46.4962C10.0044 47.6022 10.8984 48.4962 12.0044 48.4962C13.1104 48.4962 14.0044 47.6022 14.0044 46.4962V6.49622L20.5904 13.0822C21.3724 13.8642 22.6364 13.8642 23.4184 13.0822Z"
        fill={fill}
      />
    </svg>
  );
};

export default ReorderArrowUpIcon;
