import { Chip } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";

interface FilterChipsProps {
  filters: Array<{
    key: string;
    label: string;
    values: Array<{
      value: string | number | undefined;
      label: string | undefined;
    }>;
    onClear: () => void;
  }>;
  className?: string;
}

export function FilterChips({ filters, className }: FilterChipsProps) {
  return (
    <div className={`flex flex-wrap gap-2 ${className}`}>
      <AnimatePresence>
        {filters.map((filter) => (
          <motion.div
            key={filter.key}
            layout
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
            transition={{
              opacity: { duration: 0.2 },
              scale: { duration: 0.2 },
              layout: { duration: 0.3 },
            }}
          >
            <Chip
              variant="outlined"
              label={
                <p>
                  <span className="font-semibold">{filter.label}: </span>
                  {filter.values.map((v) => v.label).join(", ")}
                </p>
              }
              onDelete={filter.onClear}
            />
          </motion.div>
        ))}
      </AnimatePresence>
    </div>
  );
}
