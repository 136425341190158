import React, { useState } from "react";
import { FileDropzone } from "../../common/components/Filedropzone";

import { CheckCircle, ChevronDown, ChevronRight, ChevronUp } from "lucide-react";
import { EmployType, FileError, HealthProvider, Step } from "@/src/common/utils/domain";

export type DocumentType =
  | "dniPhotos"
  | "fullBodyPhotos"
  | "tcPhoto"
  | "payslips"
  | "djNote"
  | "debitNote"
  | "thirdPartyDni"
  | "medicalStudy"
  | "codem"
  | "form152"
  | "form184"
  | "monotributoPayment"
  | "noConformidad"
  | "formularioOPC"
  | "opc";

export interface ProviderSpecificData {
  [key: string]: string | boolean | undefined;
}

interface ProviderField {
  required: boolean;
  label: string;
  type: string;
  condition?: (formData: FormData) => boolean;
}

interface ProviderFields {
  [key: string]: ProviderField;
}

export interface MemberDocuments {
  dniPhotos: File[];
  fullBodyPhotos?: File[];
}

export interface MemberDocumentErrors {
  dniPhotos?: FileError;
  fullBodyPhotos?: FileError;
}

export interface CommonDocuments {
  tcPhoto: File[];
  payslips: File[];
  djNote: File[];
  debitNote: File[];
  thirdPartyDni: File[];
  medicalStudy: File[];
  codem?: File[];
  form152?: File[];
  form184?: File[];
  monotributoPayment?: File[];
  noConformidad?: File[];
  formularioOPC?: File[];
  opc?: File[];
}

export interface FormDocuments {
  common: CommonDocuments;
  familyMembers: {
    [memberId: string]: MemberDocuments;
  };
}
const DocumentUploader: React.FC<{
  documentType: DocumentType;
  entityId: string;
  onFilesAccepted: (files: File[]) => void;
  onError: (error: FileError) => void;
  error?: FileError;
  isRequired: boolean;
  employType: EmployType;
  healthProvider: HealthProvider;
}> = ({ documentType, entityId, onFilesAccepted, onError, error, isRequired }) => {
  const labels: Record<DocumentType, string> = {
    dniPhotos: "DNI frente/dorso",
    fullBodyPhotos: "Foto cuerpo entero",
    tcPhoto: "TC/CBU",
    payslips: "Recibos sueldo",
    djNote: "DJ con nota",
    debitNote: "Nota adhesión débito",
    thirdPartyDni: "DNI titular tercero",
    medicalStudy: "Estudio médico",
    codem: "CODEM",
    form152: "Formulario 152",
    form184: "Formulario 184",
    monotributoPayment: "Pago monotributo",
    noConformidad: "Nota de conformidad",
    formularioOPC: "Formulario OPC",
    opc: "Nota OPC",
  };

  return (
    <div>
      <label className="mb-1 block text-sm font-medium text-gray-700">
        {labels[documentType]}
        {isRequired && <span className="text-red-500">*</span>}
      </label>
      <FileDropzone
        documentType={documentType}
        entityName="sales"
        entityId={entityId}
        maxFiles={documentType === "dniPhotos" ? 2 : 1}
        onFilesAccepted={onFilesAccepted}
        onError={onError}
        error={error}
      />
    </div>
  );
};

export interface DocumentErrors {
  common?: {
    [key in keyof CommonDocuments]?: FileError;
  };
  familyMembers?: {
    [memberId: string]: MemberDocumentErrors;
  };
}

export interface FormErrors {
  domicilio?: string;
  mail?: string;
  tel?: string;
  documents?: DocumentErrors;
  providerData?: {
    [key: string]: string;
  };
}

interface FamilyMember {
  id: string;
  type: "titular" | "couple" | "son";
  title: string;
}

export interface FormData {
  domicilio: string;
  mail: string;
  tel: string;
  horarioContactacion: string;
  hasPreexistencia: boolean;
  isThirdPartyDebit: boolean;
  documents: FormDocuments;
  providerData: ProviderSpecificData;
}
const createInitialFormState = (couple: boolean, sons: number): FormData => {
  return {
    domicilio: "",
    mail: "",
    tel: "",
    horarioContactacion: "",
    hasPreexistencia: false,
    isThirdPartyDebit: false,
    documents: createInitialFormDocuments(couple, sons),
    providerData: {},
  };
};
const STEPS: { [key in HealthProvider]: Step[] } = {
  doctored: [
    {
      id: 1,
      title: "Datos Personales",
      description: "Información básica de contacto",
    },
    {
      id: 2,
      title: "Documentación grupo familiar",
      description: "DNI, fotos y datos bancarios",
    },
    {
      id: 3,
      title: "Documentación DOCTORED",
      description: "DJ y adhesión al débito",
    },
    {
      id: 4,
      title: "Documentación médica",
      description: "Información sobre preexistencias",
    },
  ],
  avalian: [
    {
      id: 1,
      title: "Datos Personales",
      description: "Información básica y de contacto",
    },
    {
      id: 2,
      title: "Documentación",
      description: "DNI, comprobantes y formularios",
    },
    {
      id: 3,
      title: "Medio de Pago",
      description: "Información de pago y débito",
    },
  ],
  sancor: [
    {
      id: 1,
      title: "Datos de Contacto",
      description: "Mail, teléfono y datos básicos",
    },
    {
      id: 2,
      title: "Documentación",
      description: "DNI, certificados y comprobantes",
    },
    {
      id: 3,
      title: "Nota de Conformidad",
      description: "Firma y aceptación",
    },
  ],
  premedic: [
    {
      id: 1,
      title: "Datos Personales",
      description: "Información de contacto",
    },
    {
      id: 2,
      title: "Documentación",
      description: "DNI, fotos y formularios",
    },
    {
      id: 3,
      title: "Auditoría Médica",
      description: "Validación médica",
    },
    {
      id: 4,
      title: "Vigencia",
      description: "Confirmación y pago",
    },
  ],
};

const createInitialFormDocuments = (couple: boolean, sons: number): FormDocuments => {
  const familyMembers: { [key: string]: MemberDocuments } = {
    titular: {
      dniPhotos: [],
      fullBodyPhotos: [],
    },
  };

  if (couple) {
    familyMembers.couple = {
      dniPhotos: [],
      fullBodyPhotos: [],
    };
  }

  for (let i = 1; i <= sons; i++) {
    familyMembers[`son-${i}`] = {
      dniPhotos: [],
    };
  }

  return {
    common: {
      tcPhoto: [],
      payslips: [],
      djNote: [],
      debitNote: [],
      thirdPartyDni: [],
      medicalStudy: [],
    },
    familyMembers,
  };
};
const getRequiredDocuments = (provider: HealthProvider, employType: EmployType): DocumentType[] => {
  const base: DocumentType[] = ["dniPhotos", "tcPhoto"];
  console.log("provider", provider);
  switch (provider) {
    case "doctored":
      return [...base, "djNote", "debitNote"];
    case "premedic":
      return employType === 3
        ? [...base, "form152", "form184"]
        : employType === 1
          ? ([...base, "payslips", "codem", "formularioOPC"] as DocumentType[])
          : base;
    case "avalian":
      return employType === 3
        ? ([...base, "monotributoPayment", "opc"] as DocumentType[])
        : employType === 1
          ? ([...base, "payslips", "codem", "opc"] as DocumentType[])
          : ([...base, "opc"] as DocumentType[]);
    case "sancor":
      return employType === 1
        ? ([...base, "payslips", "noConformidad"] as DocumentType[])
        : ([...base, "noConformidad"] as DocumentType[]);
  }
};
const PROVIDER_FIELDS: Record<HealthProvider, Record<EmployType, ProviderFields>> = {
  doctored: {
    1: {
      domicilio: { required: true, label: "Domicilio", type: "text" },
      mail: { required: true, label: "Mail", type: "text" },
      tel: { required: true, label: "Teléfono", type: "text" },
      horarioContactacion: { required: true, label: "Horario de contactación", type: "text" },
    },
    2: {
      // Campos particulares
      domicilio: { required: true, label: "Domicilio", type: "text" },
      mail: { required: true, label: "Mail", type: "text" },
      tel: { required: true, label: "Teléfono", type: "text" },
    },
    3: {
      // Campos monotributistas (igual a particulares)
      domicilio: { required: true, label: "Domicilio", type: "text" },
      mail: { required: true, label: "Mail", type: "text" },
      tel: { required: true, label: "Teléfono", type: "text" },
    },
  },
  premedic: {
    1: {
      peso: { required: true, label: "Peso", type: "text" },
      altura: { required: true, label: "Altura", type: "text" },
      imc: { required: true, label: "IMC", type: "number" },
      claveFiscal: { required: true, label: "Clave Fiscal", type: "text" },
    },
    2: {
      peso: { required: true, label: "Peso", type: "text" },
      altura: { required: true, label: "Altura", type: "text" },
      imc: { required: true, label: "IMC", type: "number" },
    },
    3: {
      peso: { required: true, label: "Peso", type: "text" },
      altura: { required: true, label: "Altura", type: "text" },
      imc: { required: true, label: "IMC", type: "number" },
    },
  },
  avalian: {
    1: {
      peso: { required: true, label: "Peso", type: "text" },
      altura: { required: true, label: "Altura", type: "text" },
      claveFiscal: { required: true, label: "Clave Fiscal", type: "text" },
    },
    2: {
      peso: { required: true, label: "Peso", type: "text" },
      altura: { required: true, label: "Altura", type: "text" },
    },
    3: {
      peso: { required: true, label: "Peso", type: "text" },
      altura: { required: true, label: "Altura", type: "text" },
    },
  },
  sancor: {
    1: {
      peso: { required: true, label: "Peso", type: "text" },
      altura: { required: true, label: "Altura", type: "text" },
      fum: { required: false, label: "FUM (mujeres 13-51 años)", type: "date" },
      claveFiscal: { required: true, label: "Clave Fiscal", type: "text" },
    },
    2: {
      peso: { required: true, label: "Peso", type: "text" },
      altura: { required: true, label: "Altura", type: "text" },
      fum: { required: false, label: "FUM (mujeres 13-51 años)", type: "date" },
    },
    3: {
      peso: { required: true, label: "Peso", type: "text" },
      altura: { required: true, label: "Altura", type: "text" },
      fum: { required: false, label: "FUM (mujeres 13-51 años)", type: "date" },
    },
  },
};

interface FamilyDocumentsAccordionProps {
  member: FamilyMember;
  isOpen: boolean;
  id: string;
  onToggle: () => void;
  onFilesAccepted: (files: File[], documentType: DocumentType, memberId: string) => void;
  onError: (error: FileError, documentType: DocumentType, memberId: string) => void;
  errors?: DocumentErrors;
  healthProvider: HealthProvider;
  requiredDocs: DocumentType[];
}
const FamilyDocumentsAccordion: React.FC<FamilyDocumentsAccordionProps> = ({
  member,
  isOpen,
  onToggle,
  onFilesAccepted,
  onError,
  errors,
  id,
  healthProvider,
  requiredDocs,
}) => {
  const getFullBodyPhotoLabel = (provider: HealthProvider) => {
    switch (provider) {
      case "premedic":
        return "Foto cuerpo entero con DNI en mano";
      case "avalian":
        return "Foto de cuerpo entero con nota OPC en mano";
      default:
        return "Foto cuerpo entero";
    }
  };

  const shouldShowFullBody =
    requiredDocs.includes("fullBodyPhotos") &&
    (member.type === "titular" || member.type === "couple");

  return (
    <div className="mb-2 rounded-lg border">
      <button
        type="button"
        className={`flex w-full items-center justify-between px-4 py-3 ${isOpen ? "bg-gray-50" : ""}`}
        onClick={onToggle}
      >
        <span className="font-medium">{member.title}</span>
        {isOpen ? <ChevronUp className="h-5 w-5" /> : <ChevronDown className="h-5 w-5" />}
      </button>

      <div className={isOpen ? "border-t p-4" : "hidden"}>
        <div className="space-y-4">
          {requiredDocs.includes("dniPhotos") && (
            <div>
              <label className="mb-1 block text-sm font-medium text-gray-700">
                DNI frente/dorso
              </label>
              <FileDropzone
                documentType="dniPhotos"
                maxFiles={2}
                entityName="sales"
                entityId={id}
                onFilesAccepted={(files) => onFilesAccepted(files, "dniPhotos", member.id)}
                onError={(error) => onError(error, "dniPhotos", member.id)}
                error={errors?.familyMembers?.[member.id]?.dniPhotos}
              />
            </div>
          )}

          {shouldShowFullBody && (
            <div>
              <label className="mb-1 block text-sm font-medium text-gray-700">
                {getFullBodyPhotoLabel(healthProvider)}
              </label>
              <FileDropzone
                documentType="fullBodyPhotos"
                maxFiles={1}
                entityName="sales"
                entityId={id}
                onFilesAccepted={(files) => onFilesAccepted(files, "fullBodyPhotos", member.id)}
                onError={(error) => onError(error, "fullBodyPhotos", member.id)}
                error={errors?.familyMembers?.[member.id]?.fullBodyPhotos}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

interface MembershipFormProps {
  employType?: EmployType;
  saleId: string;
  couple?: boolean;
  sons?: number;
  healthProvider: HealthProvider;
}
const MembershipForm: React.FC<MembershipFormProps> = ({
  employType = 1,
  couple = false,
  sons = 0,
  saleId,
  healthProvider,
}) => {
  const isDeregulated = employType === 1;
  const [currentStepIndex, setCurrentStepIndex] = useState(1);
  const currentSteps = STEPS[healthProvider];
  const stepsLength = currentSteps?.length;
  const currentStep = currentSteps ? currentSteps[currentStepIndex - 1] : STEPS.doctored[0];
  const [formData, setFormData] = useState<FormData>(() => createInitialFormState(couple, sons));
  const [errors, setErrors] = useState<FormErrors>({});
  const [openAccordion, setOpenAccordion] = useState<string>("titular");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value, type } = e.target;
    const checked = (e.target as HTMLInputElement).checked;

    if (name.startsWith("providerData.")) {
      const field = name.split(".")[1];
      setFormData((prev) => ({
        ...prev,
        providerData: {
          ...prev.providerData,
          [field]: type === "checkbox" ? checked : value,
        },
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
  };

  const handleFileAccepted = (files: File[], documentType: DocumentType, memberId?: string) => {
    setFormData((prev) => {
      if (memberId) {
        return {
          ...prev,
          documents: {
            ...prev.documents,
            familyMembers: {
              ...prev.documents.familyMembers,
              [memberId]: {
                ...prev.documents.familyMembers[memberId],
                [documentType]: files,
              },
            },
          },
        };
      } else {
        return {
          ...prev,
          documents: {
            ...prev.documents,
            common: {
              ...prev.documents.common,
              [documentType]: files,
            },
          },
        };
      }
    });

    if (memberId) {
      setErrors((prev) => ({
        ...prev,
        documents: prev.documents
          ? {
              ...prev.documents,
              familyMembers: {
                ...prev.documents.familyMembers,
                [memberId]: {
                  ...prev.documents.familyMembers?.[memberId],
                  [documentType]: undefined,
                },
              },
            }
          : {},
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        documents: prev.documents
          ? {
              ...prev.documents,
              common: {
                ...prev.documents.common,
                [documentType]: undefined,
              },
            }
          : {},
      }));
    }
  };

  const handleFileError = (error: FileError, documentType: DocumentType, memberId?: string) => {
    setErrors((prev) => {
      if (memberId) {
        return {
          ...prev,
          documents: {
            ...prev.documents,
            familyMembers: {
              ...prev.documents?.familyMembers,
              [memberId]: {
                ...prev.documents?.familyMembers?.[memberId],
                [documentType]: error,
              },
            },
          },
        };
      } else {
        return {
          ...prev,
          documents: {
            ...prev.documents,
            common: {
              ...prev.documents?.common,
              [documentType]: error,
            },
          },
        };
      }
    });
  };

  const getFamilyMembers = (): FamilyMember[] => {
    const members: FamilyMember[] = [{ id: "titular", type: "titular", title: "Titular" }];

    if (couple) {
      members.push({ id: "couple", type: "couple", title: "Pareja" });
    }

    for (let i = 1; i <= sons; i++) {
      members.push({
        id: `son-${i}`,
        type: "son",
        title: `Hijo ${i}`,
      });
    }

    return members;
  };

  const familyMembers = getFamilyMembers();

  const validateForm = (): boolean => {
    const newErrors: FormErrors = {};
    let isValid = true;

    if (!formData.domicilio) {
      newErrors.domicilio = "El domicilio es requerido";
      isValid = false;
    }
    if (!formData.mail) {
      newErrors.mail = "El email es requerido";
      isValid = false;
    }
    if (!formData.tel) {
      newErrors.tel = "El teléfono es requerido";
      isValid = false;
    }

    newErrors.documents = {
      common: {},
      familyMembers: {},
    };

    // validate each member docs
    const validateMemberDocuments = (memberId: string, isTitularOrCouple: boolean) => {
      const memberDocs = formData.documents.familyMembers[memberId];
      const memberErrors: MemberDocumentErrors = {};

      if (!memberDocs?.dniPhotos.length) {
        memberErrors.dniPhotos = {
          code: "required",
          message: `Las fotos del DNI son requeridas para ${
            memberId === "titular" ? "el titular" : memberId === "couple" ? "la pareja" : "el hijo"
          }`,
        };
        isValid = false;
      }

      if (isTitularOrCouple && !memberDocs?.fullBodyPhotos?.length) {
        memberErrors.fullBodyPhotos = {
          code: "required",
          message: `La foto de cuerpo entero es requerida para ${
            memberId === "titular" ? "el titular" : "la pareja"
          }`,
        };
        isValid = false;
      }

      if (Object.keys(memberErrors).length > 0) {
        newErrors.documents!.familyMembers![memberId] = memberErrors;
      }
    };

    validateMemberDocuments("titular", true);
    if (couple) validateMemberDocuments("couple", true);

    for (let i = 1; i <= sons; i++) {
      validateMemberDocuments(`son-${i}`, false);
    }

    if (!formData.documents.common.tcPhoto.length) {
      newErrors.documents.common!.tcPhoto = {
        code: "required",
        message: "La foto de TC/CBU es requerida",
      };
      isValid = false;
    }

    if (isDeregulated && !formData.documents.common.payslips.length) {
      newErrors.documents.common!.payslips = {
        code: "required",
        message: "Los recibos de sueldo son requeridos",
      };
      isValid = false;
    }

    if (!formData.documents.common.djNote.length) {
      newErrors.documents.common!.djNote = {
        code: "required",
        message: "La DJ con nota es requerida",
      };
      isValid = false;
    }

    if (!formData.documents.common.debitNote.length) {
      newErrors.documents.common!.debitNote = {
        code: "required",
        message: "La nota de adhesión a débito es requerida",
      };
      isValid = false;
    }

    if (formData.isThirdPartyDebit && !formData.documents.common.thirdPartyDni.length) {
      newErrors.documents.common!.thirdPartyDni = {
        code: "required",
        message: "El DNI del titular de la cuenta es requerido",
      };
      isValid = false;
    }

    if (formData.hasPreexistencia && !formData.documents.common.medicalStudy.length) {
      newErrors.documents.common!.medicalStudy = {
        code: "required",
        message: "El estudio respaldatorio es requerido",
      };
      isValid = false;
    }
    const requiredDocs = getRequiredDocuments(healthProvider, employType);
    // validate docs regardless the provider
    requiredDocs.forEach((doc) => {
      if (!formData.documents.common[doc as keyof CommonDocuments]?.length) {
        newErrors.documents!.common![doc as keyof CommonDocuments] = {
          code: "required",
          message: `El documento ${doc} es requerido`,
        };
        isValid = false;
      }
    });
    setErrors(newErrors);
    return isValid;
  };

  const renderProviderFields = () => {
    const fields = PROVIDER_FIELDS[healthProvider][employType];
    return Object.entries(fields).map(([fieldName, fieldConfig]) => (
      <div key={fieldName}>
        <label className="block text-sm font-medium text-gray-700">
          {fieldConfig.label}
          {fieldConfig.required && <span className="text-red-500">*</span>}
        </label>
        <input
          type={fieldConfig.type}
          name={`providerData.${fieldName}`}
          value={formData.providerData[fieldName] as string | undefined}
          onChange={handleInputChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
        />
      </div>
    ));
  };

  const renderStepContent = () => {
    const requiredDocs = getRequiredDocuments(healthProvider, employType);
    console.log(requiredDocs);
    switch (currentStepIndex) {
      case 1:
        return <div className="space-y-4">{renderProviderFields()}</div>;

      case 2:
        return (
          <div className="space-y-4">
            <div className="mb-6">
              {familyMembers.map((member) => (
                <FamilyDocumentsAccordion
                  key={member.id}
                  id={saleId}
                  member={member}
                  isOpen={openAccordion === member.id}
                  onToggle={() => setOpenAccordion(openAccordion === member.id ? "" : member.id)}
                  onFilesAccepted={handleFileAccepted}
                  onError={handleFileError}
                  errors={errors.documents}
                  healthProvider={healthProvider}
                  requiredDocs={requiredDocs}
                />
              ))}
            </div>

            {requiredDocs.map((docType) => (
              <DocumentUploader
                key={docType}
                documentType={docType}
                entityId={saleId}
                onFilesAccepted={(files) => handleFileAccepted(files, docType)}
                onError={(error) => handleFileError(error, docType)}
                error={errors.documents?.common?.[docType as keyof CommonDocuments]}
                isRequired={true}
                employType={employType}
                healthProvider={healthProvider}
              />
            ))}
          </div>
        );

      case 3:
        return (
          <div className="space-y-4">
            {employType === 1 && (
              <>
                <div>
                  <label className="mb-1 block text-sm font-medium text-gray-700">CODEM</label>
                  <FileDropzone
                    entityId={saleId}
                    entityName="sales"
                    documentType="codem"
                    maxFiles={1}
                    onFilesAccepted={(files) => handleFileAccepted(files, "codem")}
                    onError={(error) => handleFileError(error, "codem")}
                    error={errors.documents?.common?.codem}
                  />
                </div>
              </>
            )}

            <div className="flex items-center">
              <input
                type="checkbox"
                name="isThirdPartyDebit"
                checked={formData.isThirdPartyDebit}
                onChange={handleInputChange}
                className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
              />
              <label className="ml-2 block text-sm text-gray-700">Débito de tercero</label>
            </div>

            {formData.isThirdPartyDebit && (
              <div>
                <label className="mb-1 block text-sm font-medium text-gray-700">DNI titular</label>
                <FileDropzone
                  entityId={saleId}
                  entityName="sales"
                  documentType="thirdPartyDni"
                  maxFiles={2}
                  onFilesAccepted={(files) => handleFileAccepted(files, "thirdPartyDni")}
                  onError={(error) => handleFileError(error, "thirdPartyDni")}
                  error={errors.documents?.common?.thirdPartyDni}
                />
              </div>
            )}
          </div>
        );

      case 4:
        return (
          <div className="space-y-4">
            <div className="flex items-center">
              <input
                type="checkbox"
                name="hasPreexistencia"
                checked={formData.hasPreexistencia}
                onChange={handleInputChange}
                className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
              />
              <label className="ml-2 block text-sm text-gray-700">Preexistencias declaradas</label>
            </div>

            {formData.hasPreexistencia && (
              <div>
                <label className="mb-1 block text-sm font-medium text-gray-700">
                  Estudio respaldatorio
                </label>
                <FileDropzone
                  entityId={saleId}
                  entityName="sales"
                  documentType="medicalStudy"
                  maxFiles={1}
                  onFilesAccepted={(files) => handleFileAccepted(files, "medicalStudy")}
                  onError={(error) => handleFileError(error, "medicalStudy")}
                  error={errors.documents?.common?.medicalStudy}
                />
              </div>
            )}
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="container mx-auto max-w-4xl px-4 py-6">
      {/* Progress Steps */}
      <nav className="mb-8">
        <ol className="flex items-center">
          {STEPS[healthProvider].map((step, index) => (
            <li
              key={step.id}
              className={`flex items-center ${index < stepsLength - 1 ? "w-full" : ""}`}
            >
              <button
                onClick={() => setCurrentStepIndex(step.id)}
                className={`flex flex-col items-center ${
                  currentStepIndex >= step.id ? "text-blue-600" : "text-gray-400"
                }`}
              >
                <span
                  className={`flex h-8 w-8 items-center justify-center rounded-full border-2 ${
                    currentStepIndex >= step.id ? "border-blue-600 bg-blue-50" : "border-gray-300"
                  }`}
                >
                  {currentStepIndex > step.id ? <CheckCircle className="h-5 w-5" /> : step.id}
                </span>
                <span className="mt-1 text-xs">{step.title}</span>
              </button>

              {index < stepsLength - 1 && (
                <div className="flex w-full items-center">
                  <ChevronRight
                    className={`mx-2 h-5 w-5 ${
                      currentStepIndex > step.id ? "text-blue-600" : "text-gray-300"
                    }`}
                  />
                  <div
                    className={`h-0.5 w-full ${
                      currentStepIndex > step.id ? "bg-blue-600" : "bg-gray-200"
                    }`}
                  />
                </div>
              )}
            </li>
          ))}
        </ol>
      </nav>

      {/* Current Step Content */}
      <div className="rounded-lg bg-white p-6 shadow-sm">
        <div className="mb-6">
          <h3 className="text-lg font-semibold">{currentStep.title}</h3>
          <p className="text-sm text-gray-500">{currentStep.description}</p>
        </div>

        {renderStepContent()}
      </div>

      {/* Navigation Buttons */}
      <div className="mt-6 flex justify-between">
        <button
          type="button"
          onClick={() => setCurrentStepIndex((prev) => Math.max(1, prev - 1))}
          className={`rounded-md px-4 py-2 text-sm font-medium ${
            currentStepIndex === 1
              ? "cursor-not-allowed text-gray-400"
              : "text-blue-600 hover:bg-blue-50"
          }`}
          disabled={currentStepIndex === 1}
        >
          Anterior
        </button>

        <button
          type="button"
          onClick={() => {
            if (currentStepIndex === stepsLength) {
              validateForm();
            } else {
              setCurrentStepIndex((prev) => Math.min(stepsLength, prev + 1));
            }
          }}
          className="rounded-md bg-blue-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
        >
          {currentStepIndex === stepsLength ? "Enviar formulario" : "Siguiente"}
        </button>
      </div>
    </div>
  );
};

export default MembershipForm;
