import React, { useEffect } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { Input } from "../../common/components/Input";
import {
  Dialog,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Switch,
  TextField,
} from "@mui/material";
import { Button } from "../../common/components/Button";
import { CurrencyIcon } from "../../common/icons";
import { GetQuotePayload } from "../../quotes/quotes.api";
import { motion } from "framer-motion";
import { genderTypes, employTypes, zoneTypes } from "../../common/utils/domain";

const validAreaCodes = [
  11, 221, 383, 362, 280, 351, 379, 343, 370, 388, 2954, 380, 261, 376, 299, 2920, 387, 264, 266,
  2966, 342, 385, 2901, 381,
];

interface CotizationForm {
  firstname: string;
  email: string;
  phone: number;
  codePhone: number;
  gender: (typeof genderTypes)[number]["value"];
  age: number;
  employ: (typeof employTypes)[number]["value"];
  couple?: boolean;
  coupleage?: number;
  sonsIncluded?: boolean;
  sons?: number;
  netsalary?: number;
  childrenAges?: { age: number }[];
  zone: (typeof zoneTypes)[number]["value"];
}

export default function QuotationForm({
  setFormData,
}: {
  setFormData: React.Dispatch<React.SetStateAction<GetQuotePayload | null>>;
}) {
  const [open, setOpen] = React.useState(false);
  const {
    handleSubmit,
    register,
    control,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm<CotizationForm>({
    defaultValues: {
      age: 18,
      employ: 1,
      couple: false,
      coupleage: 18,
      sonsIncluded: false,
      sons: 1,
      childrenAges: [{ age: 0 }],
    },
    mode: "onBlur",
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "childrenAges",
  });

  const numberOfSons = watch("sons");

  useEffect(() => {
    if (!watch("sonsIncluded")) {
      setValue("sons", 1);

      setValue("childrenAges", [{ age: 0 }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("sonsIncluded"), setValue]);

  useEffect(() => {
    const currentCount = fields.length;
    const difference = numberOfSons! - currentCount;
    if (difference > 0) {
      for (let i = 0; i < difference; i++) {
        append({ age: 0 });
      }
    } else if (difference < 0) {
      for (let i = 0; i < -difference; i++) {
        remove(currentCount - i - 1);
      }
    }
  }, [numberOfSons, fields, append, remove]);

  const submit = (data: CotizationForm) => {
    setFormData({
      firstname: data.firstname,
      email: data.email,
      phone: Number(`${data.codePhone}${data.phone}`),
      age: data.age,
      employ: Number(data.employ),
      couple: data.couple ? 1 : 0,
      coupleage: data.couple && data.coupleage ? data.coupleage : 0,
      netsalary: data.netsalary ? data.netsalary : 0,
      sons: data.sons && data.sonsIncluded ? data.sons : 0,
      childrenage: data.childrenAges?.map((child) => child.age).join(";") ?? "",
      zone: Number(data.zone),
    });
  };

  return (
    <div className="p-10 md:p-10">
      <div className="flex justify-between pb-10">
        <p className="text-xl font-semibold leading-6 text-primary">Datos para cotizar</p>
        <Button size="md" onClick={() => setOpen(true)}>
          Cancelar
        </Button>
      </div>
      <motion.div
        initial={{ height: 0, opacity: 0 }}
        animate={{ height: "auto", opacity: 1 }}
        transition={{ duration: 0.15, delay: 0.1 }}
        exit={{ height: 0, opacity: 0 }}
      >
        <form onSubmit={handleSubmit(submit)}>
          <div className="grid grid-cols-1 gap-8 md:grid-cols-3 md:gap-8">
            {/* Name */}
            <div className="flex flex-col">
              <label htmlFor="name" className="text-sm font-semibold leading-6 text-primary">
                Nombre
              </label>
              <TextField
                id="name"
                error={!!errors.firstname}
                helperText={errors.firstname?.message}
                hiddenLabel
                variant="standard"
                {...register("firstname", {
                  required: "Este campo es requerido",
                })}
              />
            </div>

            {/* Age */}
            <div className="flex flex-col">
              <Input
                type="number"
                id="age"
                incremental={true}
                label="Edad"
                min={18}
                defaultValue={18}
                intent={errors.age ? "error" : "default"}
                setValue={setValue}
                watch={watch}
                errorMessage={errors.age?.message}
                {...register("age", {
                  required: "Este campo es requerido",
                  min: { value: 18, message: "Debe ser mayor de 18 años" },
                  valueAsNumber: true,
                })}
              />
            </div>

            {/* Email */}
            <div className="flex flex-col">
              <label htmlFor="email" className="text-sm font-semibold leading-6 text-primary">
                Correo electrónico
              </label>
              <TextField
                id="email"
                error={!!errors.email}
                helperText={errors.email?.message}
                hiddenLabel
                variant="standard"
                {...register("email", {
                  required: "Este campo es requerido",
                  pattern: {
                    value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                    message: "Email inválido",
                  },
                })}
              />
            </div>

            {/* Phone */}
            <div className="flex flex-col">
              <label htmlFor="phone" className="text-sm font-semibold leading-6 text-primary">
                Celular
              </label>
              <div className="grid grid-cols-3 gap-4">
                <div className="col-span-1">
                  <Input
                    id="codePhone"
                    label=""
                    type="number"
                    intent={errors.codePhone ? "error" : "default"}
                    errorMessage={errors.codePhone?.message}
                    {...register("codePhone", {
                      required: "Este campo es requerido",
                      validate: (value) => {
                        if (!validAreaCodes.includes(Number(value))) {
                          return "Código de área inválido";
                        }
                        return true;
                      },
                    })}
                  />
                </div>
                <div className="col-span-2">
                  <Input
                    id="phone"
                    label=""
                    intent={errors.phone ? "error" : "default"}
                    type="number"
                    errorMessage={errors.phone?.message}
                    {...register("phone", {
                      required: "Este campo es requerido",
                      validate: (value) => {
                        if (value.toString().length > 8 || value.toString().length < 7) {
                          return "Debe tener entre 7 y 8 dígitos";
                        }
                        return true;
                      },
                    })}
                  />
                </div>
              </div>
            </div>

            {/* Zone */}
            <div className="flex flex-col">
              <label htmlFor="zone" className="text-sm font-semibold leading-6 text-primary">
                Zona
              </label>
              <TextField
                id="zone"
                select
                error={!!errors.zone}
                defaultValue="1"
                hiddenLabel
                helperText={errors.zone?.message}
                {...register("zone", {
                  required: "Este campo es requerido",
                })}
                variant="standard"
              >
                {zoneTypes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            {/* Couple includement */}
            <div className="flex items-end justify-between py-4 md:py-8">
              <p className="text-sm font-medium leading-6 text-gray-600">Incluir pareja</p>
              <Controller
                name="couple"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FormControlLabel
                    control={<Switch checked={value} onChange={onChange} />}
                    label=""
                  />
                )}
              />
            </div>

            {/* Sons includement */}
            <div className="flex items-end justify-between py-4 md:py-8">
              <p className="text-sm font-medium leading-6 text-gray-600">Incluir hijos</p>
              <Controller
                name="sonsIncluded"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FormControlLabel
                    control={<Switch checked={value} onChange={onChange} />}
                    label=""
                  />
                )}
              />
            </div>

            {/* Couple age */}
            {watch("couple") && (
              <div className="flex flex-col">
                <Input
                  type="number"
                  id="coupleage"
                  incremental={true}
                  label="Edad pareja"
                  min={18}
                  defaultValue={18}
                  intent={errors.coupleage ? "error" : "default"}
                  setValue={setValue}
                  watch={watch}
                  errorMessage={errors.coupleage?.message}
                  {...register("coupleage", {
                    required: "Este campo es requerido",
                    min: {
                      value: 18,
                      message: "Debe ser mayor de 18 años",
                    },
                    valueAsNumber: true,
                  })}
                />
              </div>
            )}

            {/* Sons amount */}
            {watch("sonsIncluded") && (
              <div className="flex flex-col">
                <Input
                  type="number"
                  id="sons"
                  incremental={true}
                  label="Cantidad de hijos"
                  min={1}
                  defaultValue={1}
                  intent={errors.sons ? "error" : "default"}
                  setValue={setValue}
                  watch={watch}
                  errorMessage={errors.sons?.message}
                  {...register("sons", {
                    min: {
                      value: 1,
                      message: "Debe tener al menos un hijo",
                    },
                    required: "Este campo es requerido",
                    valueAsNumber: true,
                  })}
                />
              </div>
            )}

            {/* Sons ages */}
            {watch("sonsIncluded") &&
              fields.map((field, index) => (
                <div key={field.id} className="flex flex-col">
                  <Input
                    type="number"
                    incremental={true}
                    label={`Edad hijo ${index + 1}`}
                    min={0}
                    intent={errors.childrenAges?.[index]?.age ? "error" : "default"}
                    setValue={setValue}
                    watch={watch}
                    errorMessage={errors.childrenAges?.[index]?.age?.message}
                    id={`childrenAges.${index}.age`}
                    defaultValue={field.age}
                    {...register(`childrenAges.${index}.age`, {
                      required: "Este campo es requerido",
                      min: {
                        value: 0,
                        message: "La edad no puede ser negativa",
                      },
                    })}
                  />
                </div>
              ))}
            {/* Employ */}
            <div className="flex flex-col">
              <label htmlFor="employ" className="text-sm font-semibold leading-6 text-primary">
                Situación laboral
              </label>

              <TextField
                select
                hiddenLabel
                variant="standard"
                id="employ"
                error={!!errors.employ}
                defaultValue="1"
                helperText={errors.employ?.message}
                {...register("employ", {
                  required: "Este campo es requerido",
                })}
              >
                {employTypes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>

            {/* netsalary */}
            {Number(watch("employ")) === 1 && (
              <div className="flex flex-col">
                <label htmlFor="netsalary" className="text-sm font-semibold leading-6 text-primary">
                  Sueldo bruto
                </label>
                <TextField
                  type="number"
                  id="netsalary"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CurrencyIcon />
                      </InputAdornment>
                    ),
                  }}
                  error={!!errors.netsalary}
                  helperText={errors.netsalary?.message}
                  hiddenLabel
                  variant="standard"
                  {...register("netsalary", {
                    required: "Este campo es requerido",
                  })}
                />
              </div>
            )}
          </div>
          <div className="mt-8 flex justify-end">
            <Button size="md" type="submit">
              Cotizar
            </Button>
          </div>
        </form>
      </motion.div>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <div className="ContainerExport">
          <h2>¿Deseas cancelar la cotización?</h2>
          <div className="Subtitle_Exportar">
            <h3>Realizada la acción no podrá deshacerse</h3>
          </div>
          <div className="Flex">
            <Button
              onClick={() => setOpen(false)}
              variant="outlined"
              intent="secondaryOutlined"
              size="sm"
            >
              No
            </Button>
            <Button
              onClick={() => {
                setOpen(false);
                reset();
              }}
              variant="flat"
              intent="primaryFlat"
              size="sm"
            >
              Sí
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
