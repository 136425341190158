import { useState, useMemo, useCallback } from "react";
import {
  ColumnDef,
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  PaginationState,
  useReactTable,
} from "@tanstack/react-table";
import Table from "../common/components/Table";
import { Pagination } from "../common/components/Pagination";
import { Button } from "../common/components/Button";
import { Input } from "../common/components/Input";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "../common/components/Select";
import EyeOpenIcon from "../common/icons/eye-open";
import UserIcon from "../common/icons/user";
import { createBaseColumn } from "../common/utils/create-base-column";
import FiltroIcon from "../common/icons/filtro";
import MoneyBillIcon from "../common/icons/money-bill";
import { ExportButton } from "../common/components/ExportButton";
const MockData: Liquidation[] = [
  {
    id: "1",
    venta: "0001",
    vendedor: "Juan Perez",
    prepaga: "OMINT",
    plan: "100",
    cliente: "Buscaglia Maria",
    capitas: 10,
    valor: 1000,
    comision: 100,
    lote: 1,
    liquidacion: "10/10/2024",
  },
  {
    id: "2",
    venta: "0002",
    vendedor: "Maria Gomez",
    prepaga: "OMINT",
    plan: "100",
    cliente: "Buscaglia Maria",
    capitas: 10,
    valor: 1000,
    comision: 100,
    lote: 1,
    liquidacion: "10/10/2024",
  },
  {
    id: "3",
    venta: "0003",
    vendedor: "Pedro Gomez",
    prepaga: "OMINT",
    plan: "100",
    cliente: "Buscaglia Maria",
    capitas: 10,
    valor: 1000,
    comision: 100,
    lote: 1,
    liquidacion: "10/10/2024",
  },
];

const DEFAULT_PAGE_SIZE = 5;

const PAGE_SIZE_OPTIONS = [
  { value: 5, label: "5 entradas" },
  { value: 10, label: "10 entradas" },
  { value: 20, label: "20 entradas" },
  { value: 50, label: "50 entradas" },
] as const;

const columnHelper = createColumnHelper<Liquidation>();

export default function LiquidationsPage() {
  const dataKeys = useMemo(() => Object.keys(MockData?.[0] ?? {}), []);

  const columnBuilder = useMemo(() => {
    const configs: Record<string, ColumnDef<Liquidation>> = {
      // config columns that have custom logic but are based on the data
      venta: {
        header: "N° Venta",
      },
      capitas: {
        header: "Capitas",
        cell: ({ row }) => (
          <span className="flex items-center justify-center gap-2">
            <UserIcon className="aspect-square h-6 text-primary" />
            {row.original.capitas}
          </span>
        ),
      },
      valor: {
        header: "Valor",
        cell: ({ row }) => (
          <span className="items-cente flex justify-center gap-2">${row.original.valor}</span>
        ),
      },
      comision: {
        header: "Comision",
        cell: ({ row }) => (
          <span className="flex items-center justify-center gap-2 font-bold text-primary">
            <MoneyBillIcon className="aspect-square h-6 text-primary" />${row.original.comision}
          </span>
        ),
      },
    };

    const columns: ColumnDef<Liquidation>[] = [
      // add display columns here (eye and comment)
      columnHelper.display({
        id: "eye",
        cell: () => (
          <span className="mx-auto flex max-w-6 items-center justify-center">
            <EyeOpenIcon className="aspect-square h-6 text-primary" />
          </span>
        ),
      }),
    ];

    for (const key of dataKeys) {
      configs[key]
        ? columns.push(columnHelper.accessor(key as any, configs[key]))
        : columns.push(columnHelper.accessor(key as any, createBaseColumn(key)));
    }

    return columns;
  }, [dataKeys]);

  // console.log(columnBuilder);

  const [globalFilter, setGlobalFilter] = useState("");
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const table = useReactTable({
    data: MockData,
    columns: columnBuilder,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      columnVisibility: {
        id: false,
        comentarios: false,
      },
      columnOrder: [...dataKeys, "eye", "comment"],
      globalFilter,
      pagination,
    },
    onPaginationChange: setPagination,
    onGlobalFilterChange: setGlobalFilter,
    manualPagination: false,
  });

  const handlePageSizeChange = useCallback((value: string) => {
    const newSize = Number(value);
    setPagination((prev) => ({
      pageIndex: 0, // Reset to first page when changing page size
      pageSize: newSize,
    }));
  }, []);

  const currentPageSize = useMemo(
    () => PAGE_SIZE_OPTIONS.find((option) => option.value === pagination.pageSize),
    [pagination.pageSize]
  );

  const handleExport = useCallback(() => {
    // Implement your export logic here
    console.log("Exporting data...");
  }, []);

  return (
    <>
      <div className="flex h-full w-full flex-col gap-12 px-6">
        <div className="flex flex-col gap-4">
          <div className="flex flex-wrap items-center justify-between gap-4">
            <Select value={pagination.pageSize.toString()} onValueChange={handlePageSizeChange}>
              <SelectTrigger className="max-h-14 w-[170px] border border-gray-300 bg-transparent px-4 py-4 text-base focus:border-2 focus:border-primary focus:outline-none">
                <SelectValue>{currentPageSize?.label}</SelectValue>
              </SelectTrigger>
              <SelectContent className="border-gray-300">
                {PAGE_SIZE_OPTIONS.map((option) => (
                  <SelectItem
                    key={option.value}
                    value={option.value.toString()}
                    className="text-base hover:bg-purple-200"
                  >
                    {option.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <div className="flex flex-col gap-4 md:flex-row md:items-center">
              <Input
                className="max-h-14 min-w-[300px] xl:max-w-[600px] xl:grow"
                type="search"
                search
                placeholder="Buscar"
                value={globalFilter ?? ""}
                onChange={(e) => setGlobalFilter(e.target.value)}
              />
              <div className="flex items-center gap-4">
                <ExportButton
                  description="Se exportarán todas las liquidaciones."
                  onExport={handleExport}
                />
                <Button
                  variant="outlined"
                  color="primary"
                  intent="secondaryOutlined"
                  leftIcon={FiltroIcon}
                >
                  Filtrar
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className="grow">
          <Table table={table} />
        </div>

        <div className="mt-4 flex w-full flex-col flex-wrap items-center justify-center gap-2 pb-2 md:flex-row md:justify-between">
          <p className="font-bold">{table.getFilteredRowModel().rows.length} resultados</p>
          <Pagination table={table} />
        </div>
      </div>
    </>
  );
}

export type Liquidation = {
  id: string;
  venta: string;
  vendedor: string;
  prepaga: string;
  plan: string;
  cliente: string;
  capitas: number;
  valor: number;
  comision: number;
  lote: number;
  liquidacion: string;
};
