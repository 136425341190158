import { cva, VariantProps } from "class-variance-authority";
import * as React from "react";
import { capitalize } from "lodash";
import { ArrowDownIcon, ArrowUpIcon, SearchIcon } from "../icons";
import { FieldValues, UseFormSetValue } from "react-hook-form";
import { cn } from "../utils/cn";

const inputVariants = cva(
  [
    "w-full bg-transparent h-8 pb-[6px] px-4 focus:outline-none hover:border-gray-600 ",
    { "number&": "appearance-none" },
  ],
  {
    variants: {
      intent: {
        default: "border-b-[1px] border-gray-400  focus:border-primary ",
        error: "border-b-[1px] border-red-500 text-red-500 focus:border-b-2 focus:border-red-500",
        disabled: "bg-gray-200 cursor-not-allowed opacity-50",
      },
      search: {
        true: "pl-10 border-[1px] border-gray-300 leading-snug h-auto  py-2 pr-4 rounded-md justify-center placeholder:text-gray-400",
      },
    },
    defaultVariants: {
      intent: "default",
    },
  }
);

interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    VariantProps<typeof inputVariants> {
  label?: string;
  watch?: (name: keyof FieldValues) => any;
  setValue?: UseFormSetValue<any>;
  incremental?: boolean;
  errorMessage?: string;
  search?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      errorMessage,
      intent = "default",
      incremental = false,
      setValue,
      watch,
      className,
      search = false,
      ...props
    },
    ref
  ) => {
    return (
      <div
        className={cn(
          "relative flex flex-col"
          // search && "max-w-[400px] justify-center rounded-md py-4 pr-4"
        )}
      >
        {label && (
          <label className="mb-2 text-sm font-semibold leading-6 text-primary">
            {capitalize(label)}
          </label>
        )}
        <div className="relative">
          <input
            {...props}
            ref={ref}
            className={cn(inputVariants({ intent, search }), className)}
          />
          {search && (
            <div className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400">
              <SearchIcon className="aspect-square h-4" />
            </div>
          )}
          {props.type === "number" && incremental && (
            <div className="absolute inset-y-0 right-0 top-0 flex h-full flex-col justify-center pb-4 pr-4">
              <button
                type="button"
                className="p-0 text-primary disabled:text-gray-500"
                onClick={() => {
                  setValue &&
                    setValue(props?.name!, Number(watch!(props.name!)) + 1, {
                      shouldValidate: true,
                    });
                }}
                disabled={props.disabled}
              >
                <ArrowUpIcon />
              </button>
              <button
                type="button"
                className="p-0 text-primary disabled:text-gray-500"
                onClick={() =>
                  setValue &&
                  setValue(props?.name!, Number(watch!(props.name!)) - 1, {
                    shouldValidate: true,
                  })
                }
                disabled={props.disabled || Number(watch!(props.name!)) === props.min}
              >
                <ArrowDownIcon />
              </button>
            </div>
          )}
        </div>

        {errorMessage && (
          <p
            className={cn("mt-1 text-xs text-red-500 transition-opacity duration-500 ease-in-out")}
          >
            {errorMessage}
          </p>
        )}
      </div>
    );
  }
);

export { Input };
