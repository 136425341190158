import { MutationOptions, useMutation } from "@tanstack/react-query";
import { ApiError, callApi } from "../config/api";

interface UploadFilePayload {
    entityid: string;
    entityname: string;
    doc: File;
}

interface UploadResponse {
    status: number;
}

export function useUploadFileMutation(options: MutationOptions<UploadResponse, ApiError, UploadFilePayload>) {
    const user = localStorage.getItem("user");
    const token = user ? JSON.parse(user).token : null;

    return useMutation<UploadResponse, ApiError, UploadFilePayload>({
        mutationFn: (payload) => {
            const formData = new FormData();
            formData.append('entityid', String(payload.entityid));
            formData.append('entityname', payload.entityname);
            formData.append('doc', payload.doc);

            return callApi<UploadResponse>("/file/upload", {
                method: "POST",
                body: formData,
                headers: {
                    'Authorization': token
                },
                isMultipart: true
            }).then(({ data }) => data);
        },
        onSuccess: options.onSuccess,
        onError: options.onError,
    });
}