import { PAGE_SIZE_OPTIONS } from "../../common/constants/pagination";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./Select";

interface EntriesSelectProps {
  value: number;
  onValueChange: (value: string) => void;
}

export function EntriesSelect({ value, onValueChange }: EntriesSelectProps) {
  const currentPageSize = PAGE_SIZE_OPTIONS.find((option) => option.value === value);

  return (
    <Select value={value.toString()} onValueChange={onValueChange}>
      <SelectTrigger className="max-h-14 w-[170px] border border-gray-300 bg-transparent px-4 text-base hover:border hover:border-gray-600">
        <SelectValue>{currentPageSize?.label}</SelectValue>
      </SelectTrigger>
      <SelectContent className="border-gray-300">
        {PAGE_SIZE_OPTIONS.map((option) => (
          <SelectItem
            key={option.value}
            value={option.value.toString()}
            className="text-base hover:bg-purple-200"
          >
            {option.label}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}
