import { DisplayColumnDef, IdentifiedColumnDef } from '@tanstack/react-table';
import { keyFormatter } from './key-formatter';

type ColumnDef<T = unknown> =
  | DisplayColumnDef<T, unknown>
  | IdentifiedColumnDef<T, unknown>;

export const createBaseColumn = <T>(
  key: string,
  { header }: { header?: string } = {}
): ColumnDef<T> => ({
  id: key,
  header: header ?? keyFormatter(key),
  cell: (params) => (params.row.original as Record<string, unknown>)[key],
});