import { useState } from "react";
// import VentasMisVentas from "../VentasMisVentas/VentasMisVentas"
import SalesPage from "../../sales/SalesPage";
import LiquidationsPage from "../../liquidations/LiquidationsPage";
import "./VentasViewContainer.css";

const VentasViewContainer = () => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className="ventasViewContainer h-full">
      <div className="sliderBarContainer">
        <div className="sliderBar">
          <div className="sliderBarItem">
            <p onClick={() => setActiveTab(0)} className={activeTab === 0 ? "selected" : ""}>
              Mis ventas
            </p>
          </div>
          <div className="sliderBarItem">
            <p onClick={() => setActiveTab(1)} className={activeTab === 1 ? "selected" : ""}>
              Mis liquidaciones
            </p>
          </div>
        </div>
      </div>

      <div className="sliderContainer h-full overflow-visible" style={{ flexGrow: "1" }}>
        {activeTab === 0 && (
          <div className="sliderItem firstLevelItem">
            {/* old component */}
            {/* <VentasMisVentas /> */}
            <SalesPage />
          </div>
        )}
        {activeTab === 1 && (
          <div className="sliderItem firstLevelItem">
            {/* old component */}
            {/* <VentasMisLiquidaciones /> */}
            <LiquidationsPage />
          </div>
        )}
      </div>
    </div>
  );
};
export default VentasViewContainer;
