import { SaleStatus } from "@/src/sales/types/sales";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./Select";
import { cn } from "../utils/cn";
import { useCallback, useState } from "react";
import toast from "react-hot-toast";
import { ConfirmDialog } from "./ConfirmModal";

const StatusColor: Record<SaleStatus, { bg: string; text: string }> = {
  Ingresada: {
    bg: "bg-green-300",
    text: "text-green-500",
  },
  "En proceso": {
    bg: "bg-yellow-300",
    text: "text-yellow-500",
  },
  "A liquidar": {
    bg: "bg-blue-300",
    text: "text-blue-500",
  },
  Observada: {
    bg: "bg-gray-300",
    text: "text-gray-500",
  },
  Rechazada: {
    bg: "bg-red-300",
    text: "text-red-500",
  },
};

interface StatusSelectProps {
  className?: string;
  status: SaleStatus;
  onStatusChange?: (newStatus: SaleStatus) => void;
}

export const StatusSelect = ({ className, status, onStatusChange }: StatusSelectProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<SaleStatus | null>(null);

  const handleStatusSelect = useCallback(
    (newStatus: SaleStatus) => {
      if (newStatus === status) return;
      setSelectedStatus(newStatus);
      setIsOpen(true);
    },
    [status]
  );

  const handleConfirm = useCallback(() => {
    if (!selectedStatus) return;

    onStatusChange?.(selectedStatus);
    toast.success(`El estado se cambió a "${selectedStatus}" exitosamente.`);

    setIsOpen(false);
    setSelectedStatus(null);
  }, [onStatusChange, selectedStatus]);

  const handleCancel = useCallback(() => {
    setIsOpen(false);
    setSelectedStatus(null);
  }, []);

  return (
    <>
      <Select value={status} onValueChange={handleStatusSelect}>
        <SelectTrigger
          className={cn(
            "max-w-28 border-none px-2 text-sm",
            className,
            StatusColor[status].bg,
            StatusColor[status].text
          )}
        >
          <SelectValue />
        </SelectTrigger>
        <SelectContent className="m-0 p-0">
          {Object.entries(StatusColor).map(([key, value]) => (
            <SelectItem
              className={cn(
                "border-b border-gray-300 text-sm last:border-none hover:bg-primary/30",
                key === status && "font-bold"
              )}
              key={key}
              value={key}
            >
              {key}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>

      <ConfirmDialog
        isOpen={isOpen}
        onClose={handleCancel}
        onConfirm={handleConfirm}
        title="¿Deseas cambiar el estado de la venta?"
        description={`Se cambiará el estado de la venta.`}
      />
    </>
  );
};
