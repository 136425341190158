import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./Select";
import { cn } from "../utils/cn";

interface VendedorFilterProps {
  value?: string;
  onValueChange: (value: string | undefined) => void;
  className?: string;
  vendedores: string[];
}

export function VendedorFilter({
  value,
  vendedores,
  onValueChange,
  className,
}: VendedorFilterProps) {
  return (
    <Select value={value ?? ""} onValueChange={(val) => onValueChange(val as string)}>
      <SelectTrigger className={cn("min-w-[120px] max-w-[140px]", className)}>
        <SelectValue placeholder="Vendedor" />
      </SelectTrigger>
      <SelectContent>
        {vendedores.map((seller) => (
          <SelectItem
            className={cn("text-sm", value === seller ? "font-bold" : "")}
            key={seller}
            value={seller}
          >
            {seller}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}
