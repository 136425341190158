import { useFormContext } from "react-hook-form";
import { SalesFilters } from "../types/filters";
import { useCallback } from "react";
import { useDebouncedCallback } from "use-debounce";
import { Input } from "../../common/components/Input";

export function CuotaRangeFilter() {
  const { register, setValue } = useFormContext<SalesFilters>();

  // Debounced setValue to reduce rerenders
  const debouncedSetValue = useDebouncedCallback(
    (field: "min" | "max", value: number | undefined) => {
      setValue(`cuota.${field}`, value, {
        shouldDirty: true,
        shouldTouch: true,
      });
    },
    300
  );

  // Handle change with debouncing
  const handleChange = useCallback(
    (field: "min" | "max") => (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      debouncedSetValue(field, value === "" ? undefined : Number(value));
    },
    [debouncedSetValue]
  );

  return (
    <div className="flex flex-col gap-2">
      <label className="text-muted-foreground text-sm leading-none">Rango de Cuota</label>
      <div className="flex items-center gap-2">
        <div className="flex items-center gap-2">
          <span className="text-muted-foreground text-xs">$</span>
          <Input
            type="number"
            placeholder="Desde"
            className="w-20 !px-2 !pb-0 text-sm leading-normal"
            {...register("cuota.min", {
              valueAsNumber: true,
              onChange: handleChange("min"),
            })}
          />
        </div>
        <span className="text-muted-foreground text-sm">-</span>
        <div className="flex items-center gap-2">
          <span className="text-muted-foreground text-xs">$</span>
          <Input
            type="number"
            placeholder="Hasta"
            className="w-20 !px-2 !pb-1 text-sm leading-normal"
            {...register("cuota.max", {
              valueAsNumber: true,
              onChange: handleChange("max"),
            })}
          />
        </div>
      </div>
    </div>
  );
}
