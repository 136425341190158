import React, { useMemo, useState } from "react";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
  getFilteredRowModel,
  getPaginationRowModel,
  PaginationState,
  getSortedRowModel,
} from "@tanstack/react-table";
import { QuoteFlat } from "./quotes.api";
import Spinner from "../common/components/Spinner";
import { useGetListQuotationsQuery } from "./quotes.api";
import Table from "../common/components/Table";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../common/components/Select";
import { Pagination } from "../common/components/Pagination";
import UserIcon from "../common/icons/user";
import EyeOpenIcon from "../common/icons/eye-open";
import {
  Checkbox,
  Chip,
  FormControl,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select as MuiSelect,
  OutlinedInput,
} from "@mui/material";
import { HealthProvider, plans, providersOptions } from "../common/utils/domain";

import ResponsiveDialog from "../common/components/Dialog";
import QuoteDetails from "./components/QuoteDetails";
import * as XLSX from "xlsx";
import { Button } from "../common/components/Button";
import { FilterChips } from "../common/components/FilterChips";
import { AnimatePresence, motion } from "framer-motion";
import { ExportButton } from "../common/components/ExportButton";
import MembershipForm from "./components/MembershipForm";
interface FlattenedQuotation {
  quotationId: number | null;
  seller: string;
  createdAt: string;
  customer: string;
  provider: string;
  plan: string;
  capitas: number;
  originalQuote: QuoteFlat;
  originalPlan: QuoteFlat["plans"][0];
}

const PAGE_SIZE_OPTIONS = [
  { value: 5, label: "5 entradas" },
  { value: 10, label: "10 entradas" },
  { value: 20, label: "20 entradas" },
  { value: 50, label: "50 entradas" },
] as const;

const DEFAULT_PAGE_SIZE = PAGE_SIZE_OPTIONS[1].value;

const columnHelper = createColumnHelper<FlattenedQuotation>();

export default function MyQuotationsPage() {
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: DEFAULT_PAGE_SIZE,
  });
  const [selectedProviders, setSelectedProviders] = useState<string[]>([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [selectedPlans, setSelectedPlans] = useState<string[]>([]);

  const quotationsQuery = useGetListQuotationsQuery(pageIndex + 1, pageSize);

  const pagination = quotationsQuery.data?.original.pagination;

  // Aplanar los datos combinando cada QuoteFlat con sus planes
  const flattenedData = useMemo(() => {
    if (!quotationsQuery.data?.original.quotesflats) return [];
    console.log("sekected", selectedProviders);
    return (
      quotationsQuery.data.original.quotesflats
        .flatMap((quote) =>
          quote.plans.map((plan) => ({
            quotationId: quote.quotesflatsId,
            seller: quote.full_username,
            createdAt: quote.created_at,
            customer: quote.firstname,
            provider: plan.nameprovider,
            plan: plan.nameplan,
            capitas: parseInt(quote.sons) + parseInt(quote.couple) + 1,
            originalQuote: quote,
            originalPlan: plan,
          }))
        )
        // filter by selected plans, selected providers
        .filter((quote) => {
          if (selectedPlans.length > 0 && !selectedPlans.includes(quote.originalPlan.nameplan)) {
            return false;
          }

          if (
            selectedProviders.length > 0 &&
            !selectedProviders.includes(quote.originalPlan.nameprovider.toLowerCase())
          ) {
            return false;
          }

          return true;
        })
    );
  }, [quotationsQuery.data?.original.quotesflats, selectedPlans, selectedProviders]);

  const activeFilters = [
    ...(selectedProviders.length > 0
      ? [
          {
            key: "providers",
            label: "Prepagas",
            values: selectedProviders.map((provider) => ({
              value: provider,
              label: providersOptions.find((p) => p.value === provider)?.label,
            })),
            onClear: () => setSelectedProviders([]),
          },
        ]
      : []),
    ...(selectedPlans.length > 0
      ? [
          {
            key: "plans",
            label: "Planes",
            values: selectedPlans.map((plan) => ({
              value: plan,
              label: plan,
            })),
            onClear: () => setSelectedPlans([]),
          },
        ]
      : []),
  ];

  const columns = useMemo(
    () => [
      // N° Cotización
      columnHelper.accessor("quotationId", {
        header: "N° Cotización",
        cell: (info) => info.getValue()?.toString().padStart(4, "0") ?? "",
      }),
      // Vendedor
      columnHelper.accessor("seller", {
        header: "Vendedor",
      }),
      // Fecha
      columnHelper.accessor("createdAt", {
        header: "Fecha",
        cell: (info) => new Date(info.getValue()).toLocaleDateString("es-AR"),
      }),
      // Cliente
      columnHelper.accessor("customer", {
        header: "Cliente",
      }),
      // Prepaga
      columnHelper.accessor("provider", {
        header: "Prepaga",
      }),
      // Plan
      columnHelper.accessor("plan", {
        header: "Plan",
      }),
      // Cápitas
      columnHelper.accessor("capitas", {
        header: "Cápitas",
        cell: (info) => (
          <span className="flex items-center justify-center gap-2">
            <UserIcon className="aspect-square h-6 text-primary" />
            {info.getValue()}
          </span>
        ),
      }),
      // Columnas de acciones
      columnHelper.display({
        id: "actions",
        cell: (info) => (
          <div className="flex justify-center gap-2">
            <ResponsiveDialog
              title="Detalles de la cotización"
              trigger={<button className="">detalles </button>}
            >
              <QuoteDetails quote={info.row.original.originalQuote} />
            </ResponsiveDialog>
            <span className="cursor-pointer">
              <ResponsiveDialog
                isFullScreen={true}
                title="Detalles de la cotización"
                trigger={
                  <button className="">
                    <EyeOpenIcon className="aspect-square h-6 text-primary hover:text-primary/80" />
                  </button>
                }
              >
                <MembershipForm
                  saleId={String(info.row.original.quotationId!)}
                  sons={+info.row.original.originalQuote.sons}
                  couple={info.row.original.originalQuote.couple !== "0"}
                  employType={+info.row.original.originalQuote.employ! as 1 | 2 | 3}
                  healthProvider={
                    info.row.original.originalPlan.nameprovider.toLowerCase() as HealthProvider
                  }
                />
              </ResponsiveDialog>
            </span>
          </div>
        ),
      }),
    ],
    []
  );

  const table = useReactTable({
    data: flattenedData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      globalFilter,
      pagination: {
        pageIndex,
        pageSize,
      },
    },
    onPaginationChange: setPagination,
    onGlobalFilterChange: setGlobalFilter,
    enableSorting: true,
    pageCount: quotationsQuery.data?.original.pagination.totalPages ?? -1,
    manualPagination: true,
  });

  const handleDownload = () => {
    // Map the data to only include the columns we want to export
    const exportData = flattenedData.map((row) => ({
      "N° Cotización": row.quotationId?.toString().padStart(4, "0") || "",
      Vendedor: row.seller,
      Fecha: new Date(row.createdAt).toLocaleDateString("es-AR"),
      Cliente: row.customer,
      Prepaga: row.provider,
      Plan: row.plan,
      Cápitas: row.capitas,
    }));

    // Create a new workbook and worksheet
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(exportData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Cotizaciones");

    // Generate and download the file
    XLSX.writeFile(wb, "cotizaciones.xlsx");
  };

  if (quotationsQuery.isLoading) {
    return (
      <div className="mt-20 flex justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="flex h-full w-full flex-col">
      <div className="flex flex-col gap-4">
        <div className="flex justify-between px-10">
          <div className="flex flex-wrap items-center gap-4">
            <Select
              value={pageSize.toString()}
              onValueChange={(value) => {
                setPagination((prev) => ({
                  pageIndex: 0,
                  pageSize: Number(value),
                }));
              }}
            >
              <SelectTrigger className="max-h-10 w-[170px] border border-gray-300 bg-transparent px-4 py-4 text-base focus:border-2 focus:border-primary focus:outline-none">
                <SelectValue>{pageSize} entradas</SelectValue>
              </SelectTrigger>
              <SelectContent className="border-gray-300">
                {PAGE_SIZE_OPTIONS.map((option) => (
                  <SelectItem
                    key={option.value}
                    value={option.value.toString()}
                    className="text-base hover:bg-purple-200"
                  >
                    {option.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>

            <FormControl>
              <MuiSelect
                multiple
                size="small"
                displayEmpty
                value={selectedPlans}
                onChange={(event: any) => {
                  const {
                    target: { value },
                  } = event;
                  setSelectedPlans(value);
                }}
                input={<OutlinedInput />}
                renderValue={() => <p>Planes</p>}
                inputProps={{ "aria-label": "Without label" }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 300,
                    },
                  },
                }}
              >
                {plans.map((plan) => (
                  <MenuItem key={plan} value={plan}>
                    <Checkbox checked={selectedPlans.includes(plan)} />
                    <ListItemText primary={plan} />
                  </MenuItem>
                ))}
              </MuiSelect>
            </FormControl>
            <FormControl>
              <MuiSelect
                multiple
                size="small"
                displayEmpty
                value={selectedProviders}
                onChange={(event: any) => {
                  const {
                    target: { value },
                  } = event;
                  setSelectedProviders(value);
                }}
                input={<OutlinedInput />}
                renderValue={() => <p>Prepagas</p>}
                inputProps={{ "aria-label": "Without label" }}
              >
                {providersOptions.map((provider) => (
                  <MenuItem key={provider.value} value={provider.value}>
                    <Checkbox checked={selectedProviders.includes(provider.value)} />
                    <ListItemIcon>
                      <img
                        src={provider.image}
                        alt={provider.label}
                        style={{ width: 30, height: 30, marginRight: 10 }}
                      />
                    </ListItemIcon>
                    <ListItemText primary={provider.label} />
                  </MenuItem>
                ))}
              </MuiSelect>
            </FormControl>
          </div>
          <ExportButton
            description="Se exportarán todas las cotizaciones."
            onExport={handleDownload}
          />
        </div>
        <div className="relative mb-2 h-[80px] w-full overflow-visible">
          {/* Contenedor absoluto de los filtros */}
          <div className="absolute inset-0">
            <AnimatePresence>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
                className="flex gap-2 overflow-hidden bg-purple-200 px-10 py-4"
              >
                <Chip label={`${pageSize} entradas`} variant="outlined" />
                <FilterChips filters={activeFilters} />
              </motion.div>
            </AnimatePresence>
          </div>
        </div>
      </div>

      <div className="w-full overflow-x-auto px-10">
        <Table table={table} />
      </div>

      <div className="mt-2 flex w-full flex-col flex-wrap items-center justify-center gap-2 px-10 pb-2 md:flex-row md:justify-between">
        <p className="font-bold">{pagination?.totalRecords} resultados</p>
        <Pagination table={table} />
      </div>
    </div>
  );
}
