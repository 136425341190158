import { ColumnFiltersState } from "@tanstack/react-table";
import { useCallback, useEffect, useMemo } from "react";
import type { Dispatch, SetStateAction } from "react";
import { UseFormReturn, useWatch } from "react-hook-form";
import { format } from "date-fns";
import { SalesFilters } from "../types/filters";
import { providersOptions } from "../../common/utils/domain";

interface UseFiltersProps {
  methods: UseFormReturn<SalesFilters>;
  setColumnFilters: Dispatch<SetStateAction<ColumnFiltersState>>;
}

export function useFilters({ methods, setColumnFilters }: UseFiltersProps) {
  const { control, setValue } = methods;

  // Update table filters when form changes
  const updateTableFilters = useCallback(
    (data: SalesFilters) => {
      const newFilters: ColumnFiltersState = [];

      if (data.prepaga?.length) {
        newFilters.push({
          id: "prepaga",
          value: data.prepaga,
        });
      }

      if (data.vigencia?.from || data.vigencia?.to) {
        newFilters.push({
          id: "vigencia",
          value: {
            from: data.vigencia.from,
            to: data.vigencia.to,
          },
        });
      }

      if (data.estado) {
        newFilters.push({
          id: "estado",
          value: data.estado,
        });
      }

      if (data.vendedor) {
        newFilters.push({
          id: "vendedor",
          value: data.vendedor,
        });
      }

      if (data.cuota?.min !== undefined || data.cuota?.max !== undefined) {
        newFilters.push({
          id: "cuota",
          value: {
            min: data.cuota.min,
            max: data.cuota.max,
          },
        });
      }

      setColumnFilters(newFilters);
    },
    [setColumnFilters]
  );

  useEffect(() => {
    const subscription = methods.watch((data) => {
      updateTableFilters(data as SalesFilters);
    });

    return () => subscription.unsubscribe();
  }, [methods, updateTableFilters]);

  // Provider options mapping
  const providerOptionsMap = useMemo(
    () => Object.fromEntries(providersOptions.map((p) => [p.value, p.label])),
    []
  );

  // Watch form values
  const prepagaValue = useWatch({ control, name: "prepaga" });
  const estadoValue = useWatch({ control, name: "estado" });
  const vigenciaValue = useWatch({ control, name: "vigencia" });
  const vendedorValue = useWatch({ control, name: "vendedor" });
  const cuotaValue = useWatch({ control, name: "cuota" });

  // Clear handlers
  const handleClearPrepaga = useCallback((): void => setValue("prepaga", []), [setValue]);
  const handleClearEstado = useCallback((): void => setValue("estado", undefined), [setValue]);
  const handleClearVendedor = useCallback((): void => setValue("vendedor", undefined), [setValue]);
  const handleClearVigencia = useCallback(() => {
    setValue(
      "vigencia",
      { from: undefined, to: undefined },
      {
        shouldDirty: true,
        shouldTouch: true,
      }
    );
    setColumnFilters(
      (prev: ColumnFiltersState) =>
        prev.filter((filter) => filter.id !== "vigencia") as ColumnFiltersState
    );
  }, [setValue, setColumnFilters]);

  const handleClearCuota = useCallback(() => {
    setValue("cuota", { min: undefined, max: undefined });
  }, [setValue]);

  const activeFilters = useMemo(
    () => [
      {
        key: "prepaga",
        label: "Prepagas",
        values: (prepagaValue || []).map((value) => ({
          value,
          label: providerOptionsMap[value] || value,
        })),
        onClear: handleClearPrepaga,
      },
      {
        key: "estado",
        label: "Estado",
        values: estadoValue ? [{ value: estadoValue, label: estadoValue }] : [],
        onClear: handleClearEstado,
      },
      {
        key: "vigencia",
        label: "Vigencia",
        values: (() => {
          if (!vigenciaValue?.from && !vigenciaValue?.to) return [];

          return [
            {
              value: "dateRange",
              label: `${vigenciaValue.from ? format(vigenciaValue.from, "dd/MM/yyyy") : ""} - ${
                vigenciaValue.to ? format(vigenciaValue.to, "dd/MM/yyyy") : ""
              }`,
            },
          ];
        })(),
        onClear: handleClearVigencia,
      },
      {
        key: "vendedor",
        label: "Vendedor",
        values: vendedorValue ? [{ value: vendedorValue, label: vendedorValue }] : [],
        onClear: handleClearVendedor,
      },
      {
        key: "cuota",
        label: "Cuota",
        values: (() => {
          if (!cuotaValue?.min && !cuotaValue?.max) return [];
          return [
            {
              value: "range",
              label: `$${cuotaValue.min || 0} - $${cuotaValue.max || "∞"}`,
            },
          ];
        })(),
        onClear: handleClearCuota,
      },
    ],
    [
      prepagaValue,
      estadoValue,
      vigenciaValue,
      vendedorValue,
      cuotaValue,
      providerOptionsMap,
      handleClearPrepaga,
      handleClearEstado,
      handleClearVigencia,
      handleClearVendedor,
      handleClearCuota,
    ]
  );

  return {
    activeFilters: activeFilters.filter((f) => f.values.length > 0),
  };
}
