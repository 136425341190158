import React from "react";
import { GetQuotePayload, useGetQuoteQuery } from "./quotes.api";
import QuotationForm from "./components/QuotationForm";
import Spinner from "../common/components/Spinner";
import QuotationResults from "./components/QuotationResults";

export default function QuotationPage() {
  const [formData, setFormData] = React.useState<GetQuotePayload | null>(null);
  const { data, isLoading } = useGetQuoteQuery(formData);

  if (isLoading) {
    return (
      <div className="mt-20 flex justify-center">
        <Spinner />
      </div>
    );
  }

  if (data && formData) {
    return (
      <QuotationResults
        formData={formData}
        quotationResults={data ? data.reduce((acc: any, curr: any) => [...acc, ...curr], []) : []}
        onBack={() => setFormData(null)}
      />
    );
  }

  return <QuotationForm setFormData={setFormData} />;
}
