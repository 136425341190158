import { SVGProps } from "react";

const CommentIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M48.5003 23.0205C48.2111 18.3452 46.5586 13.8566 43.7467 10.1082C40.9348 6.3598 37.0863 3.51535 32.6757 1.92554C28.2652 0.335739 23.4853 0.0700768 18.9254 1.16131C14.3655 2.25254 10.225 4.65297 7.01421 8.06665C3.80343 11.4803 1.66279 15.758 0.856188 20.3724C0.0495905 24.9868 0.612297 29.7361 2.47494 34.0349C4.33758 38.3336 7.41872 41.9939 11.3385 44.5644C15.2583 47.1349 19.8454 48.5034 24.5343 48.501H38.5003C41.1517 48.4983 43.6937 47.445 45.5685 45.572C47.4432 43.6991 48.4977 41.1597 48.5003 38.511V23.0205ZM44.5003 38.511C44.5003 40.1007 43.8682 41.6253 42.7429 42.7494C41.6177 43.8735 40.0916 44.505 38.5003 44.505H24.5343C21.7122 44.5037 18.922 43.9082 16.3457 42.7573C13.7694 41.6065 11.4649 39.926 9.5823 37.8256C7.69065 35.7263 6.27016 33.2472 5.41609 30.5545C4.56202 27.8618 4.2941 25.0178 4.63031 22.2133C5.16118 17.7896 7.15068 13.6682 10.2852 10.4988C13.4197 7.32947 17.5209 5.29241 21.9423 4.70877C22.8048 4.60078 23.6731 4.54606 24.5423 4.54493C29.2033 4.53223 33.7199 6.15912 37.3003 9.14034C39.3914 10.8765 41.1077 13.0188 42.3454 15.4373C43.583 17.8558 44.3161 20.5003 44.5003 23.2103V38.511Z"
      fill="currentColor"
    />
    <path
      d="M16.5 18.5486H24.5C25.0304 18.5486 25.5391 18.3379 25.9142 17.9628C26.2893 17.5877 26.5 17.079 26.5 16.5486C26.5 16.0181 26.2893 15.5094 25.9142 15.1344C25.5391 14.7593 25.0304 14.5486 24.5 14.5486H16.5C15.9696 14.5486 15.4609 14.7593 15.0858 15.1344C14.7107 15.5094 14.5 16.0181 14.5 16.5486C14.5 17.079 14.7107 17.5877 15.0858 17.9628C15.4609 18.3379 15.9696 18.5486 16.5 18.5486Z"
      fill="currentColor"
    />
    <path
      d="M32.5 22.5486H16.5C15.9696 22.5486 15.4609 22.7593 15.0858 23.1344C14.7107 23.5094 14.5 24.0182 14.5 24.5486C14.5 25.079 14.7107 25.5877 15.0858 25.9628C15.4609 26.3379 15.9696 26.5486 16.5 26.5486H32.5C33.0304 26.5486 33.5391 26.3379 33.9142 25.9628C34.2893 25.5877 34.5 25.079 34.5 24.5486C34.5 24.0182 34.2893 23.5094 33.9142 23.1344C33.5391 22.7593 33.0304 22.5486 32.5 22.5486Z"
      fill="currentColor"
    />
    <path
      d="M32.5 30.5486H16.5C15.9696 30.5486 15.4609 30.7593 15.0858 31.1344C14.7107 31.5094 14.5 32.0182 14.5 32.5486C14.5 33.079 14.7107 33.5877 15.0858 33.9628C15.4609 34.3379 15.9696 34.5486 16.5 34.5486H32.5C33.0304 34.5486 33.5391 34.3379 33.9142 33.9628C34.2893 33.5877 34.5 33.079 34.5 32.5486C34.5 32.0182 34.2893 31.5094 33.9142 31.1344C33.5391 30.7593 33.0304 30.5486 32.5 30.5486Z"
      fill="currentColor"
    />
  </svg>
);

export default CommentIcon;
