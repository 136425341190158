import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Button } from "./Button";

interface ConfirmDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  description: string;
  confirmText?: string;
  cancelText?: string;
}

export function ConfirmDialog({
  isOpen,
  onClose,
  onConfirm,
  title,
  description,
  confirmText = "Confirmar",
  cancelText = "Cancelar",
}: ConfirmDialogProps) {
  return (
    <Dialog className="text-center" open={isOpen} onClose={onClose}>
      <div className="flex flex-col items-center gap-2 !p-4 md:!p-11">
        <DialogTitle className="!p-0 !text-lg !font-bold !text-primary">{title}</DialogTitle>
        <DialogContent className="!p-0">
          <DialogContentText className="!text-sm !text-black">{description}</DialogContentText>
        </DialogContent>
        <DialogActions className="mt-4 flex w-full !justify-between !p-0">
          <Button onClick={onClose} variant="outlined" color="primary" intent="secondaryOutlined">
            {cancelText}
          </Button>
          <Button onClick={onConfirm} intent="primaryFlat">
            {confirmText}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
}
