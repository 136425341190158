import { useState } from "react";
import { GetQuoteResponse } from "../quotes.api";
import { BigArrowDown, PinIcon } from "../../common/icons";
import { getLogo } from "../../common/utils/domain";
import { cn } from "../../common/utils/cn";
import { capitalize } from "lodash";
import reintegroIcon from "../../common/assets/reintegro-icono.png";
import internacionIcon from "../../common/assets/internacion-icono.png";
import coberturaIcon from "../../common/assets/cobertura-icono.png";

interface QuoteCardProps {
  quote: GetQuoteResponse;
  order?: number;
  selectedPlans?: GetQuoteResponse[] | undefined;
  setSelectedPlans?: (selectedPlans: GetQuoteResponse[]) => void;
  isInHeader?: boolean;
  setOpenDetails?: () => void;
  isInteractive?: boolean;
}
function QuoteCard({
  quote,
  order,
  selectedPlans,
  setSelectedPlans,
  isInHeader = false,
  setOpenDetails,
  isInteractive = true,
  ...props
}: QuoteCardProps) {
  const isSelected = selectedPlans?.some((plan) => plan.planId === quote.planId);
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleSelection = () => {
    if (!setSelectedPlans) return;

    if (isSelected) {
      setSelectedPlans(selectedPlans?.filter((plan) => plan.planId !== quote.planId) || []);
    } else {
      setSelectedPlans([...(selectedPlans || []), quote]);
    }
  };

  return (
    <div
      {...props}
      className={cn(
        // add classname prop

        "flex flex-col rounded-2xl bg-white shadow-sm",

        order! <= 3 && "border-4 border-primary"
      )}
    >
      {/*Recomended header*/}
      {order! <= 3 && (
        <div className="flex h-10 items-center justify-center bg-primary">
          <p className="font-semibold text-white">Recomendado</p>
        </div>
      )}
      <div className="flex h-full flex-col justify-between p-6">
        <div>
          {/* Header */}
          <div className="mb-4 flex w-full items-center justify-between">
            <img src={getLogo(quote.nameprovider)} alt={quote.nameprovider} className="h-8 w-8" />
            <p className="text-lg font-bold leading-6 text-primary">{quote.nameplan}</p>
            {isInteractive && (
              <button
                onClick={toggleSelection}
                disabled={selectedPlans && selectedPlans.length >= 3 && !isSelected}
                className={cn(
                  "opacity-90 transition-opacity duration-150 ease-in-out hover:opacity-100",
                  isSelected ? "text-primary" : "text-gray-300"
                )}
              >
                <PinIcon className="h-6 w-6" />
              </button>
            )}
          </div>
          <div className="flex flex-col gap-4 space-y-2 text-lg">
            {/* Data */}
            <div className="flex justify-between">
              <p>Costo total</p>
              <p className="font-bold text-primary">${quote.price}</p>
            </div>
            {/* Benefits */}
            {(!isInHeader || isExpanded) && (
              <div className="mt-4 flex flex-col gap-2">
                <p className="text-center font-semibold text-primary">Beneficios destacados</p>
                <div className="flex flex-col space-y-2">
                  <div className="flex items-center justify-center gap-4">
                    <div className="flex items-center">
                      <img src={reintegroIcon} alt="Reintegro" className="mr-2 h-6 w-6" />
                      <p>{quote.copayment}</p>
                    </div>
                    <div className="flex items-center">
                      <img src={coberturaIcon} alt="Cobertura" className="mr-2 h-6 w-6" />
                      <p>{quote.coverage}</p>
                    </div>
                  </div>
                  <div className="flex items-center justify-center">
                    <img src={internacionIcon} alt="Habitación" className="mr-2 h-6 w-6" />
                    <p>
                      {quote.hospitalization !== "ST - SL - SC"
                        ? capitalize(quote.hospitalization)
                        : quote.hospitalization}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Footer and actions */}
        {isInHeader ? (
          <div className="mt-4 flex justify-center">
            <button onClick={() => setIsExpanded(!isExpanded)}>
              <BigArrowDown className={isExpanded ? "rotate-180 transform" : ""} />
            </button>
          </div>
        ) : (
          <>
            {isInteractive && (
              <button className="w-full font-bold text-primary" onClick={setOpenDetails}>
                Quiero más detalles
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
}
export default QuoteCard;
