import { ChevronLeft, ChevronRight, ChevronsLeft, ChevronsRight } from "lucide-react";
import { Button } from "./Button";
import { Table } from "@tanstack/react-table";
import { cn } from "../utils/cn";

interface PaginationProps<T> {
  table: Table<T>;
}

export function Pagination<T>({ table }: PaginationProps<T>) {
  const pageCount = table.getPageCount();

  // Don't render pagination if there's only one page or no pages
  if (pageCount <= 1) return null;

  return (
    <div className="flex items-center gap-2">
      <Button
        color="primary"
        intent="secondaryOutlined"
        className="h-6 w-6 p-1"
        onClick={() => table.setPageIndex(0)}
        disabled={!table.getCanPreviousPage()}
      >
        <ChevronsLeft className="aspect-square h-full" />
      </Button>
      <Button
        color="primary"
        intent="secondaryOutlined"
        className="h-6 w-6 p-1"
        onClick={() => table.previousPage()}
        disabled={!table.getCanPreviousPage()}
      >
        <ChevronLeft className="aspect-square h-full" />
      </Button>
      {Array.from({ length: pageCount }, (_, i) => i + 1).map((pageNumber) => (
        <Button
          key={pageNumber}
          variant={pageNumber === table.getState().pagination.pageIndex + 1 ? "outlined" : "flat"}
          color="primary"
          intent={
            pageNumber === table.getState().pagination.pageIndex + 1
              ? "secondaryOutlined"
              : "secondaryOutlined"
          }
          className={cn(
            "flex h-8 w-8 rounded-md p-0",
            pageNumber === table.getState().pagination.pageIndex + 1
              ? "border-primary bg-primary text-white"
              : "bg-transparent text-black hover:border hover:border-primary hover:bg-transparent hover:text-primary"
          )}
          onClick={() => table.setPageIndex(pageNumber - 1)}
        >
          {pageNumber}
        </Button>
      ))}
      <Button
        color="primary"
        intent="secondaryOutlined"
        className="h-6 w-6 p-1"
        onClick={() => table.nextPage()}
        disabled={!table.getCanNextPage()}
      >
        <ChevronRight className="aspect-square h-full" />
      </Button>
      <Button
        color="primary"
        intent="secondaryOutlined"
        className="h-6 w-6 p-1"
        onClick={() => table.setPageIndex(pageCount - 1)}
        disabled={!table.getCanNextPage()}
      >
        <ChevronsRight className="aspect-square h-full" />
      </Button>
    </div>
  );
}
