import { capitalize, debounce } from "lodash";
import { GetQuotePayload, GetQuoteResponse, useCreateQuoteMutation } from "../quotes.api";
import { useEffect, useMemo, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import internacionIcon from "../../common/assets/internacion-icono.png";
import domicilioIcon from "../../common/assets/domicilio-icono.png";
import ortodonciaIcon from "../../common/assets/ortodoncia-icono.png";
import consultasIcon from "../../common/assets/consultas-icono.png";
import implatesIcon from "../../common/assets/implante-icono.png";
import odontologiaIcon from "../../common/assets/odontologia-icono.png";
import oftalmologiaIcon from "../../common/assets/oftalmologia-icono.png";
import opticaIcon from "../../common/assets/optica-icono.png";
import guardiaIcon from "../../common/assets/guardia-icono.png";
import mentalIcon from "../../common/assets/mental-icono.png";
import cirugiaIcon from "../../common/assets/cirugia-icono.png";
import maternidadIcon from "../../common/assets/maternidad-icono.png";
import laboratorioIcon from "../../common/assets/laboratorio-icono.png";
import diagnosticoIcon from "../../common/assets/diagnostico-icono.png";
import pesoIcon from "../../common/assets/peso-icono.png";
import kinesiologiaIcon from "../../common/assets/kinesiologia-icono.png";
import farmaciaIcon from "../../common/assets/farmacia-icono.png";
import { BigArrowLeft, BigArrowRight, SearchIcon } from "../../common/icons";
import { AnimatePresence, motion } from "framer-motion";
import {
  Checkbox,
  Chip,
  Dialog,
  DialogContent,
  FormControl,
  IconButton,
  InputBase,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { Button } from "../../common/components/Button";
import toast from "react-hot-toast";
import {
  benefitsOptions,
  providersOptions,
  employTypes,
  zoneTypes,
} from "../../common/utils/domain";
import { PrepagasSelect } from "../../common/components/PrepagasSelect";
import QuoteCard from "./QuoteCard";

interface QuotationResultsProps {
  formData: GetQuotePayload;
  quotationResults: GetQuoteResponse[];
  onBack: () => void;
}

function getOrderLabel(orderBy: "relevance" | "maxPrice" | "minPrice") {
  switch (orderBy) {
    case "relevance":
      return "Relevancia";
    case "maxPrice":
      return "Menor precio";
    case "minPrice":
      return "Mayor precio";
  }
}

function Filters({
  age,
  employType,
  zone,
  selectedProviders,
  setSelectedProviders,
  selectedBenefits,
  setSelectedBenefits,
  search,
  setSearch,
  maxPrice,
  setMaxPrice,
  orderBy,
  setOrderBy,
  goBack,
  isSaveDisabled,
  saveQuotations,
  isSaving,
}: {
  age: number;
  employType: { value: number; label: string };
  zone: { value: number; label: string };
  selectedProviders: string[];
  setSelectedProviders: (selected: string[]) => void;
  selectedBenefits: string[];
  setSelectedBenefits: (selected: string[]) => void;
  search: string;
  setSearch: (search: string) => void;
  maxPrice?: number;
  setMaxPrice: (price: number | undefined) => void;
  orderBy: "relevance" | "maxPrice" | "minPrice";
  setOrderBy: (orderBy: "relevance" | "maxPrice" | "minPrice") => void;
  goBack: () => void;
  isSaveDisabled: boolean;
  saveQuotations: () => void;
  isSaving: boolean;
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const getChipLabel = () => {
    if (selectedBenefits.length === benefitsOptions.length) {
      return (
        <p>
          <span className="font-semibold">Beneficios:</span>
          Todos
        </p>
      );
    }

    const selectedLabels = selectedBenefits
      .map((value) => benefitsOptions.find((option) => option.value === value))
      .map((option) => option?.label)
      .filter(Boolean);

    if (isMobile) {
      const count = selectedLabels.length;
      return (
        <p>
          <span className="font-semibold">Beneficios: </span>`${count} seleccionado$
          {count !== 1 ? "s" : ""}`
        </p>
      );
    }

    if (selectedLabels.length > 4) {
      return (
        <p>
          <span className="font-semibold">Beneficios: </span>
          `${selectedLabels.slice(0, 4).join(", ")}...`
        </p>
      );
    }

    return (
      <p>
        <span className="font-semibold">Beneficios: </span>
        {selectedLabels.join(", ")}
      </p>
    );
  };

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="grid grid-cols-1 gap-4 px-6 md:grid-cols-8 md:px-10">
        <PrepagasSelect
          selectedProviders={selectedProviders}
          onProvidersChange={setSelectedProviders}
        />
        <FormControl>
          <Select
            multiple
            size="small"
            displayEmpty
            value={selectedBenefits}
            onChange={(event: any) => {
              const {
                target: { value },
              } = event;
              setSelectedBenefits(value);
            }}
            input={<OutlinedInput />}
            renderValue={() => <p>Beneficios</p>}
            inputProps={{ "aria-label": "Without label" }}
            MenuProps={{
              PaperProps: { sx: { maxHeight: 350 } },
            }}
          >
            {benefitsOptions.map((benefit) => (
              <MenuItem key={benefit.value} value={benefit.value}>
                <Checkbox checked={selectedBenefits.includes(benefit.value)} />

                <ListItemText primary={benefit.label} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div className="col-span-2">
          <Paper
            component="form"
            sx={{
              display: "flex",
              alignItems: "center",
              boxShadow: "none",
              border: "1px solid #ccc",
              background: "transparent",
              width: "100%",
              height: 40,
            }}
          >
            <div className="px-2">
              <SearchIcon className="py-1" />
            </div>

            <InputBase
              placeholder="Buscar por clinicas"
              className="w-full"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Paper>
        </div>

        <div className="col-span-1">
          <Paper
            component="form"
            sx={{
              display: "flex",
              alignItems: "center",
              boxShadow: "none",
              border: "1px solid #ccc",
              background: "transparent",
              width: "100%",
              height: 40,
            }}
          >
            <div className="px-2">
              <img src={pesoIcon} alt="peso" className="w-6" />
            </div>

            <InputBase
              placeholder="Precio hasta"
              type="number"
              className="w-full"
              value={maxPrice ?? ""}
              onChange={(e) => setMaxPrice(parseInt(e.target.value))}
            />
          </Paper>
        </div>
        <div className="col-span-1">
          <Select
            size="small"
            value={orderBy}
            onChange={(event: any) => {
              const {
                target: { value },
              } = event;
              setOrderBy(value);
            }}
            input={<OutlinedInput />}
            renderValue={() => <p>Ordenar por</p>}
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value="relevance">
              <ListItemText primary={getOrderLabel("relevance")} />
            </MenuItem>
            <MenuItem value="maxPrice">
              <ListItemText primary={getOrderLabel("maxPrice")} />
            </MenuItem>
            <MenuItem value="minPrice">
              <ListItemText primary={getOrderLabel("minPrice")} />
            </MenuItem>
          </Select>
        </div>
        <div className="col-span-2 flex justify-end gap-2">
          <Button size="xs" onClick={goBack}>
            Volver
          </Button>
          <Button size="xs" disabled={isSaveDisabled} onClick={saveQuotations} isLoading={isSaving}>
            Guardar
          </Button>
        </div>
      </div>

      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: "auto" }}
          exit={{ opacity: 0, height: 0 }}
          transition={{ duration: 0.3 }}
          className="overflow-hidden bg-purple-200"
        >
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ delay: 0.1, duration: 0.3 }}
            className="px-6 py-4 md:px-10"
          >
            <div className="flex flex-wrap gap-2">
              <Chip label={`${age} años`} variant="outlined" />
              <Chip label={`${employType.label}`} variant="outlined" />
              <Chip label={`${zone.label}`} variant="outlined" />
              <Chip label={getOrderLabel(orderBy)} variant="outlined" />
              {selectedProviders.length > 0 && (
                <AnimatePresence>
                  <motion.div
                    key="selected"
                    layout
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.8 }}
                    transition={{
                      opacity: { duration: 0.2 },
                      scale: { duration: 0.2 },
                      layout: { duration: 0.3 },
                    }}
                  >
                    <Chip
                      variant="outlined"
                      label={
                        selectedProviders.length === providersOptions.length ? (
                          <p>
                            <span className="font-semibold">Prepagas:</span>
                            Todas
                          </p>
                        ) : (
                          <p>
                            <span className="font-semibold">Prepagas:</span>
                            {` ${selectedProviders
                              .map((value) =>
                                providersOptions.find((option) => option.value === value)
                              )
                              .map((option) => option?.label)
                              .join(", ")}
                          `}
                          </p>
                        )
                      }
                      onDelete={() => setSelectedProviders([])}
                    />
                  </motion.div>
                </AnimatePresence>
              )}
              {selectedBenefits.length > 0 && (
                <AnimatePresence>
                  <motion.div
                    key="selectedBenefits"
                    layout
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.8 }}
                    transition={{
                      opacity: { duration: 0.2 },
                      scale: { duration: 0.2 },
                      layout: { duration: 0.3 },
                    }}
                  >
                    <Chip
                      sx={{
                        height: "auto",
                        minHeight: 32,
                        maxWidth: "100%",
                        "& .MuiChip-label": {
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        },
                      }}
                      variant="outlined"
                      label={getChipLabel()}
                      onDelete={() => setSelectedBenefits([])}
                    />
                  </motion.div>
                </AnimatePresence>
              )}

              {maxPrice && maxPrice.toString().length >= 5 && (
                <Chip
                  label={`Precio hasta $${maxPrice}`}
                  variant="outlined"
                  onDelete={() => setMaxPrice(undefined)}
                />
              )}
            </div>
          </motion.div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
}

function QuoteDetails({ quote }: { quote: GetQuoteResponse }) {
  const [selectedZoneId, setSelectedZoneId] = useState("");

  const availableZones = useMemo(() => {
    if (!quote.clinics || quote.clinics.length === 0) return [];
    const zoneIds = Array.from(new Set(quote.clinics.map((clinic) => clinic.zoneId)));
    return zoneTypes.filter((zone) => zoneIds.includes(zone.value));
  }, [quote.clinics]);

  const filteredClinics = useMemo(() => {
    if (!quote.clinics) return [];
    return selectedZoneId
      ? quote.clinics.filter((clinic) => clinic.zoneId === parseInt(selectedZoneId))
      : quote.clinics;
  }, [selectedZoneId, quote.clinics]);

  const handleZoneChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setSelectedZoneId(event.target.value);
  };

  useEffect(() => {
    if (availableZones.length > 0 && !selectedZoneId) {
      setSelectedZoneId(availableZones[0].value.toString());
    }
  }, [availableZones, selectedZoneId]);

  return (
    <div className="px-2 md:px-6">
      <h2 className="mb-4 text-2xl font-bold">{`${quote.nameprovider}/${quote.baseplan}`}</h2>

      {quote.clinics && quote.clinics.length > 0 && (
        <div className="mb-6 flex flex-col gap-4">
          <h3 className="mb-2 text-lg font-semibold text-primary">Clínicas y Sanatorios</h3>

          <div className="flex flex-col">
            <label htmlFor="zone" className="text-sm font-semibold leading-6 text-primary">
              Zona
            </label>
            <TextField
              id="zone"
              select
              value={selectedZoneId}
              hiddenLabel
              onChange={handleZoneChange}
              variant="standard"
            >
              {availableZones.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </div>

          <div className="grid grid-cols-3 gap-6">
            {filteredClinics.map((clinic) => (
              <p className="text-sm font-semibold text-gray-600" key={clinic.clinicId}>
                {capitalize(clinic.clinicName.toLowerCase())}
              </p>
            ))}
          </div>
        </div>
      )}
      <h3 className="mb-2 text-lg font-semibold text-primary">Beneficios</h3>

      <div className="grid grid-cols-2 gap-4">
        <>
          <div className="flex">
            <img src={internacionIcon} alt="Habitación" className="mr-2 h-6 w-6" />
            <span>Internación</span>
          </div>
          <div className="flex">
            <span>{quote.hospitalization.toUpperCase()}</span>
          </div>
        </>
        <div className="col-span-2 h-px bg-gray-200" />
        <>
          <div className="flex">
            <img src={mentalIcon} alt="psicologia" className="mr-2 h-6 w-6" />
            <span>Psicologia</span>
          </div>
          <div className="flex">
            <span>{capitalize(quote.psychology)}</span>
          </div>
        </>
        <div className="col-span-2 h-px bg-gray-200" />
        <>
          <div className="flex">
            <p className="h-4">
              <img src={domicilioIcon} alt="Cobertura" className="mr-2 h-6 w-6" />
            </p>
            <span>Médico a domicilio </span>
          </div>
          <div className="flex">
            <span>{quote.home_visits}</span>
          </div>
        </>
        <div className="col-span-2 h-px bg-gray-200" />
        <>
          <div className="flex">
            <img src={maternidadIcon} alt="Maternidad" className="mr-2 h-6 w-6" />
            <span>Maternidad</span>
          </div>
          <div className="flex">
            <span>{quote.PMI}</span>
          </div>
        </>
        <div className="col-span-2 h-px bg-gray-200" />
        <>
          <div className="flex">
            <img src={farmaciaIcon} alt="Farmacia" className="mr-2 h-6 w-6" />
            <span>Farmacia</span>
          </div>
          <div className="flex">
            <span>{quote.outpatient_medications.toLowerCase()}</span>
          </div>
        </>
        <div className="col-span-2 h-px bg-gray-200" />
        <>
          <div className="flex">
            <img src={cirugiaIcon} alt="Cirugía" className="mr-2 h-6 w-6" />
            <span>Cirugía estética</span>
          </div>
          <div className="flex">
            <span>{quote.cosmetic_surgery}</span>
          </div>
        </>
        <div className="col-span-2 h-px bg-gray-200" />
        <>
          <div className="flex">
            <img src={laboratorioIcon} alt="Laboratorio" className="mr-2 h-6 w-6" />
            <span>Laboratorio</span>
          </div>
          <div className="flex">
            <span>{quote.laboratory}</span>
          </div>
        </>
        <div className="col-span-2 h-px bg-gray-200" />
        <>
          <div className="flex">
            <img src={diagnosticoIcon} alt="Diagnostico" className="mr-2 h-6 w-6" />
            <span>Diagnóstico por imagen</span>
          </div>
          <div className="flex">
            <span>{quote.diagnostic_imaging}</span>
          </div>
        </>
        <div className="col-span-2 h-px bg-gray-200" />
        <>
          <div className="flex">
            <img src={kinesiologiaIcon} alt="Kinesiologia" className="mr-2 h-6 w-6" />
            <span>Kinesiologia</span>
          </div>
          <div className="flex">
            <span>{quote.physical_therapy}</span>
          </div>
        </>
        <div className="col-span-2 h-px bg-gray-200" />
        <>
          <div className="flex">
            <p className="h-4">
              <img src={guardiaIcon} alt="guardia" className="mr-2 h-6 w-6" />
            </p>
            <span>Guardia </span>
          </div>
          <div className="flex">
            <span>{quote.emergency_room}</span>
          </div>
        </>
        <div className="col-span-2 h-px bg-gray-200" />
        <>
          <div className="flex">
            <img src={consultasIcon} alt="Consultas" className="mr-2 h-6 w-6" />
            <span>Consultas médicas </span>
          </div>
          <div className="flex">
            <p className="h-4"></p>
            <span>{quote.office_visit}</span>
          </div>
        </>
        <div className="col-span-2 h-px bg-gray-200" />
        <>
          <div className="flex">
            <img src={ortodonciaIcon} alt="Ortodoncia" className="mr-2 h-6 w-6" />
            <span>Ortodoncia </span>
          </div>
          <div className="flex">
            <span>{quote.orthodontics}</span>
          </div>
        </>
        <div className="col-span-2 h-px bg-gray-200" />
        <>
          <div className="flex">
            <img src={implatesIcon} alt="Implantes" className="mr-2 h-6 w-6" />
            <span>Implantes y prótesis dentales</span>
          </div>
          <div className="flex">
            <span>{quote.prosthesis.toUpperCase()}</span>
          </div>
        </>
        <div className="col-span-2 h-px bg-gray-200" />
        <>
          <div className="flex">
            <img src={opticaIcon} alt="optica" className="mr-2 h-6 w-6" />
            <span>Óptica</span>
          </div>
          <div className="flex">
            <span>{quote.optics}</span>
          </div>
        </>
        <div className="col-span-2 h-px bg-gray-200" />
        <>
          <div className="flex">
            <img src={oftalmologiaIcon} alt="oftalmologia" className="mr-2 h-6 w-6" />
            <span>Oftalmologia</span>
          </div>
          <div className="flex">
            <span>{quote.ophthalmology.toUpperCase()}</span>
          </div>
        </>
        <div className="col-span-2 h-px bg-gray-200" />
        <>
          <div className="flex">
            <img src={odontologiaIcon} alt="odontologia" className="mr-2 h-6 w-6" />
            <span>Odontologia</span>
          </div>
          <div className="flex">
            <span>{quote.dentistry.toUpperCase()}</span>
          </div>
        </>
      </div>
    </div>
  );
}

export default function QuotationResults({
  formData,
  quotationResults,
  onBack,
}: QuotationResultsProps) {
  const [selectedPlans, setSelectedPlans] = useState<GetQuoteResponse[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [maxPrice, setMaxPrice] = useState<number | undefined>(undefined);
  const [orderBy, setOrderBy] = useState<"relevance" | "maxPrice" | "minPrice">("relevance");
  const [selectedProviders, setSelectedProviders] = useState<string[]>([]);
  const [selectedBenefits, setSelectedBenefits] = useState<string[]>([]);
  const [quotationDetails, setQuotationDetails] = useState<GetQuoteResponse>();
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [search, setSearch] = useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const createQuotationsMutation = useCreateQuoteMutation({
    onSuccess: () => {
      toast.success("Cotizaciones guardadas correctamente");
      debounce(() => {
        onBack();
      }, 1000)();
    },
    onError: () => {
      toast.error("Ocurrió un error al guardar las cotizaciones");
    },
  });

  const user = localStorage.getItem("user");
  const userJson = user ? JSON.parse(user) : null;

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % selectedPlans.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + selectedPlans.length) % selectedPlans.length);
  };

  const filteredResults = quotationResults
    .filter((quote) => {
      if (selectedProviders.length > 0) {
        if (!selectedProviders.includes(quote.nameprovider.toLowerCase())) {
          return false;
        }
      }

      if (selectedBenefits.length > 0) {
        if (selectedBenefits.some((benefit) => quote[benefit as keyof GetQuoteResponse] === null)) {
          return false;
        }
      }

      if (search) {
        if (
          !quote.clinics?.some((clinic) =>
            clinic.clinicName.toLowerCase().includes(search.toLowerCase())
          )
        ) {
          return false;
        }
      }
      if (maxPrice && maxPrice.toString().length >= 5) {
        if (parseInt(String(quote.price)) > maxPrice) {
          return false;
        }
      }

      return true;
    })
    .sort((a, b) => {
      if (orderBy === "relevance") {
        return 0;
      }
      if (orderBy === "maxPrice") {
        return a.price - b.price;
      }
      if (orderBy === "minPrice") {
        return b.price - a.price;
      }
      return 0;
    });

  return (
    <>
      {/* Filters */}
      <Filters
        age={formData.age}
        employType={employTypes.find((employ) => employ.value === formData.employ)!}
        zone={zoneTypes.find((zone) => zone.value === formData.zone)!}
        selectedProviders={selectedProviders}
        setSelectedProviders={setSelectedProviders}
        selectedBenefits={selectedBenefits}
        setSelectedBenefits={setSelectedBenefits}
        maxPrice={maxPrice}
        setMaxPrice={setMaxPrice}
        search={search}
        setSearch={setSearch}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        goBack={onBack}
        isSaveDisabled={selectedPlans.length === 0}
        saveQuotations={() => {
          createQuotationsMutation.mutate({
            userId: userJson.id,
            firstname: formData.firstname,
            email: formData.email,
            phone: String(formData.phone),
            age: formData.age,
            netsalary: formData.netsalary,
            employ: String(formData.employ),
            zone: String(formData.zone),
            sons: String(formData.sons),
            childrenage: formData.childrenage,
            coupleage: formData.coupleage,
            couple: String(formData.couple),
            plans: selectedPlans.map((plan) => ({
              quotedvalue: plan.price,
              nameplan: plan.nameplan,
              nameprovider: plan.nameprovider,
              cost: plan.price,
            })),
          });
        }}
        isSaving={createQuotationsMutation.status === "pending"}
      />

      {/* Selected plans */}
      <AnimatePresence>
        {selectedPlans.length > 0 && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
            className="overflow-hidden bg-purple-100"
          >
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ delay: 0.1, duration: 0.3 }}
              className="px-4 py-4 md:px-10"
            >
              {/* Desktop View */}
              <div className="hidden md:grid md:grid-cols-3 md:gap-6">
                <AnimatePresence>
                  {selectedPlans.map((selectedPlan) => (
                    <motion.div
                      key={selectedPlan.planId}
                      layout
                      initial={{ opacity: 0, scale: 0.8 }}
                      animate={{ opacity: 1, scale: 1 }}
                      exit={{ opacity: 0, scale: 0.8 }}
                      transition={{
                        opacity: { duration: 0.2 },
                        scale: { duration: 0.2 },
                        layout: { duration: 0.3 },
                      }}
                    >
                      <QuoteCard
                        quote={selectedPlan}
                        selectedPlans={selectedPlans}
                        setSelectedPlans={setSelectedPlans}
                        isInHeader
                      />
                    </motion.div>
                  ))}
                </AnimatePresence>
              </div>

              {/* Mobile Carousel */}
              <div className="md:hidden">
                <div className="relative">
                  <div className="relative overflow-hidden">
                    <motion.div
                      className="flex"
                      animate={{ x: `${-currentIndex * 100}%` }}
                      transition={{ duration: 0.3, ease: "easeInOut" }}
                    >
                      {selectedPlans.map((selectedPlan) => (
                        <div key={selectedPlan.planId} className="w-full flex-shrink-0 px-2">
                          <QuoteCard
                            quote={selectedPlan}
                            selectedPlans={selectedPlans}
                            setSelectedPlans={setSelectedPlans}
                            isInHeader
                          />
                        </div>
                      ))}
                    </motion.div>
                  </div>
                  {selectedPlans.length > 1 && (
                    <>
                      <button
                        onClick={prevSlide}
                        className="top-2/2 -translate-y-2/2 absolute left-0 transform p-2"
                      >
                        <BigArrowLeft className="h-6 w-6 text-primary" />
                      </button>
                      <button
                        onClick={nextSlide}
                        className="top-2/2 -translate-y-2/2 absolute right-0 transform p-2"
                      >
                        <BigArrowRight className="h-6 w-6 text-primary" />
                      </button>
                    </>
                  )}
                </div>
                <div className="mt-4 flex justify-center space-x-2">
                  {selectedPlans.map((_, index) => (
                    <div
                      key={index}
                      className={`h-2 w-2 rounded-full ${
                        index === currentIndex ? "bg-primary" : "bg-gray-300"
                      }`}
                    />
                  ))}
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      <Dialog
        open={isDetailsOpen}
        onClose={() => {
          setIsDetailsOpen(false);
          setQuotationDetails(undefined);
        }}
        aria-labelledby="Quotation Details"
        aria-describedby="Quotation Details"
        className="flex items-center justify-center"
        maxWidth="xl"
        fullWidth
        fullScreen={fullScreen}
      >
        <DialogContent>
          <div className="flex justify-end px-2 md:px-6">
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setIsDetailsOpen(false);
                setQuotationDetails(undefined);
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </div>
          {quotationDetails && <QuoteDetails quote={quotationDetails} />}
        </DialogContent>
      </Dialog>

      {/* Quotation Results */}
      <div className="grid min-h-[436px] grid-cols-1 gap-4 px-6 py-4 md:grid-cols-3 xl:gap-x-20 xl:px-10">
        {filteredResults.map((quote, index) => (
          <QuoteCard
            key={quote.planId}
            quote={quote}
            order={index}
            selectedPlans={selectedPlans}
            setSelectedPlans={setSelectedPlans}
            isInHeader={false}
            setOpenDetails={() => {
              setIsDetailsOpen(true);
              setQuotationDetails(quote);
            }}
          />
        ))}
      </div>
    </>
  );
}
