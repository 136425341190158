import {
  FormControl,
  ListItemIcon,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { Checkbox } from "@mui/material";

import { providersOptions } from "../utils/domain";

interface PrepagasSelectProps {
  selectedProviders: string[];
  onProvidersChange: (providers: string[]) => void;
  className?: string;
}

export function PrepagasSelect({
  selectedProviders,
  onProvidersChange,
  className,
}: PrepagasSelectProps) {
  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value;
    onProvidersChange(typeof value === "string" ? [value] : value);
  };

  return (
    <FormControl className={className}>
      <Select
        multiple
        size="small"
        displayEmpty
        value={selectedProviders}
        onChange={handleChange}
        input={<OutlinedInput />}
        renderValue={() => <p>Prepagas</p>}
        inputProps={{ "aria-label": "Without label" }}
      >
        {providersOptions.map((provider) => (
          <MenuItem key={provider.value} value={provider.value}>
            <Checkbox checked={selectedProviders.includes(provider.value)} />
            <ListItemIcon>
              <img
                src={provider.image}
                alt={provider.label}
                style={{ width: 30, height: 30, marginRight: 10 }}
              />
            </ListItemIcon>
            <ListItemText primary={provider.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
