import Table from "../common/components/Table";
import { useCallback, useMemo, useState } from "react";
import { Input } from "../common/components/Input";
import { Pagination } from "../common/components/Pagination";
import { CommentDialog } from "./components/CommentDialog";
import { ExportButton } from "../common/components/ExportButton";
import Filters from "./components/Filters";
import { EntriesSelect } from "../common/components/EntriesSelect";
import { SalesFilters } from "./types/filters";
import { FormProvider, useForm } from "react-hook-form";
import { FilterChips } from "../common/components/FilterChips";
import { Sale } from "./types/sales";
import { useTableColumns } from "./hooks/useTableColumns";
import { useFilters } from "./hooks/useFilters";
import { useTableConfig } from "./hooks/useTableConfig";
import { AnimatePresence, motion } from "framer-motion";
import { Chip } from "@mui/material";

// remove mock when api is ready
const MockData: Sale[] = [
  {
    id: 1,
    venta: "0001",
    estado: "Ingresada",
    vendedor: "Juan Perez",
    cliente: "Maria Gomez",
    prepaga: "OMINT",
    plan: 100,
    capitas: 1,
    cuota: 1267.98,
    vigencia: "2024-01-15",
    lote: "01/24",
    comentarios: [
      {
        usuario: "Juan Perez",
        comentario: "Comentario 1",
        fecha: "2024-01-01",
      },
    ],
  },
  {
    id: 2,
    venta: "0002",
    estado: "En proceso",
    vendedor: "Peña Florencia",
    cliente: "Maria Gomez",
    prepaga: "OMINT",
    plan: 500,
    capitas: 2,
    cuota: 1267.98,
    vigencia: "2023-12-01",
    lote: "12/23",
    comentarios: [
      {
        usuario: "Juan Perez",
        comentario: "Comentario 1",
        fecha: "2023-12-01",
      },
    ],
  },
  {
    id: 3,
    venta: "0003",
    estado: "A liquidar",
    vendedor: "Juan Perez",
    cliente: "Roberto Gomez",
    prepaga: "SWISS MEDICAL",
    plan: 200,
    capitas: 1,
    cuota: 3000.24,
    vigencia: "2022-06-15",
    lote: "06/22",
    comentarios: [],
  },
  {
    id: 4,
    venta: "0004",
    estado: "Observada",
    vendedor: "Juan Perez",
    cliente: "Roberto Gomez",
    prepaga: "OMINT",
    plan: 100,
    capitas: 1,
    cuota: 1267.98,
    vigencia: "2021-03-01",
    lote: "03/21",
  },
  {
    id: 5,
    venta: "0005",
    estado: "Ingresada",
    vendedor: "Ana Martinez",
    cliente: "Carlos Rodriguez",
    prepaga: "SWISS MEDICAL",
    plan: 300,
    capitas: 3,
    cuota: 2500.0,
    vigencia: "2020-08-15",
    lote: "08/20",
  },
  {
    id: 6,
    venta: "0006",
    estado: "A liquidar",
    vendedor: "Laura Garcia",
    cliente: "Pedro Sanchez",
    prepaga: "GALENO",
    plan: 400,
    capitas: 2,
    cuota: 1890.5,
    vigencia: "2024-02-01",
    lote: "02/24",
    comentarios: [],
  },
  {
    id: 7,
    venta: "0007",
    estado: "Rechazada",
    vendedor: "Martin Lopez",
    cliente: "Sofia Torres",
    prepaga: "GALENO",
    plan: 150,
    capitas: 1,
    cuota: 1500.75,
    vigencia: "2023-09-20",
    lote: "09/23",
  },
  {
    id: 8,
    venta: "0008",
    estado: "En proceso",
    vendedor: "Carolina Ruiz",
    cliente: "Lucas Fernandez",
    prepaga: "SWISS MEDICAL",
    plan: 250,
    capitas: 4,
    cuota: 3200.0,
    vigencia: "2022-11-30",
    lote: "11/22",
  },
  {
    id: 9,
    venta: "0009",
    estado: "Ingresada",
    vendedor: "Diego Morales",
    cliente: "Victoria Paz",
    prepaga: "OMINT",
    plan: 350,
    capitas: 2,
    cuota: 2100.25,
    vigencia: "2021-07-10",
    lote: "07/21",
  },
  {
    id: 10,
    venta: "0010",
    estado: "A liquidar",
    vendedor: "Lucia Diaz",
    cliente: "Gabriel Castro",
    prepaga: "GALENO",
    plan: 200,
    capitas: 1,
    cuota: 1750.8,
    vigencia: "2020-04-25",
    lote: "04/20",
  },
  {
    id: 11,
    venta: "0011",
    estado: "A liquidar",
    vendedor: "Lucia Diaz",
    cliente: "Gabriel Castro",
    prepaga: "GALENO",
    plan: 200,
    capitas: 1,
    cuota: 1750.8,
    vigencia: "2025-02-03",
    lote: "04/20",
  },
  {
    id: 12,
    venta: "0012",
    estado: "A liquidar",
    vendedor: "Lucia Diaz",
    cliente: "Gabriel Castro",
    prepaga: "GALENO",
    plan: 200,
    capitas: 1,
    cuota: 1750.8,
    vigencia: "2025-01-18",
    lote: "04/20",
  },
];

export default function SalesPage() {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedSale, setSelectedSale] = useState<Sale | null>(null);

  const handleOpenDialog = useCallback((sale: Sale) => {
    setSelectedSale(sale);
    setOpenDialog(true);
  }, []);

  const handleCloseDialog = useCallback(() => {
    setOpenDialog(false);
    setSelectedSale(null);
  }, []);

  const dataKeys = useMemo(() => Object.keys(MockData?.[0] ?? {}), []);

  const methods = useForm<SalesFilters>({
    defaultValues: {
      estado: undefined,
      vendedor: undefined,
      prepaga: undefined,
      cliente: undefined,
      plan: undefined,
      vigencia: {
        from: undefined,
        to: undefined,
      },
    },
  });

  const columns = useTableColumns({
    dataKeys,
    onOpenDialog: handleOpenDialog,
  });

  const { table, globalFilter, setGlobalFilter, setColumnFilters, pagination, setPagination } =
    useTableConfig({
      data: MockData,
      columns,
      dataKeys,
    });

  const { activeFilters } = useFilters({
    methods,
    setColumnFilters,
  });

  const handlePageSizeChange = useCallback(
    (value: string) => {
      setPagination((prev) => ({
        pageIndex: 0,
        pageSize: Number(value),
      }));
    },
    [setPagination]
  );

  const handleExport = useCallback(() => {
    // Implement your export logic here
    console.log("Exporting data...");
  }, []);

  // Get unique sellers for filters
  const uniqueSellers = useMemo(() => {
    return MockData ? Array.from(new Set(MockData.map((sale) => sale.vendedor))).sort() : [];
  }, []);

  return (
    <FormProvider {...methods}>
      <div className="flex h-full w-full flex-col">
        <div className="flex flex-col gap-4">
          <div className="flex flex-wrap items-center justify-between gap-4 px-10">
            <EntriesSelect value={pagination.pageSize} onValueChange={handlePageSizeChange} />
            <Filters vendedores={uniqueSellers} />
            <div className="flex flex-col gap-4 md:flex-row md:items-center">
              <Input
                className="max-h-14 min-w-[300px] xl:max-w-[600px] xl:grow"
                type="search"
                search
                placeholder="Buscar"
                value={globalFilter ?? ""}
                onChange={(e) => setGlobalFilter(e.target.value)}
              />
              <div className="flex items-center gap-4">
                <ExportButton
                  description="Se exportarán todas las ventas."
                  onExport={handleExport}
                />
              </div>
            </div>
          </div>
          <div className="relative h-[80px] w-full overflow-visible">
            {/* Contenedor absoluto de los filtros */}
            <div className="absolute inset-0">
              <AnimatePresence>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3 }}
                  className="flex gap-2 overflow-hidden bg-purple-200 px-10 py-4"
                >
                  <Chip label={`${pagination.pageSize} entradas`} variant="outlined" />
                  <FilterChips filters={activeFilters} />
                </motion.div>
              </AnimatePresence>
            </div>
          </div>
        </div>

        <div className="grow px-10">
          <Table table={table} />
        </div>

        <div className="mt-4 flex w-full flex-col flex-wrap items-center justify-center gap-2 px-10 pb-2 md:flex-row md:justify-between">
          <p className="font-bold">{table.getFilteredRowModel().rows.length} resultados</p>
          <Pagination table={table} />
        </div>
      </div>

      <CommentDialog open={openDialog} onClose={handleCloseDialog} sale={selectedSale} />
    </FormProvider>
  );
}
