import React from "react";
import { QuoteFlat } from "../quotes.api";
import { Button } from "../../common/components/Button";
import { employTypes, zoneTypes } from "../../common/utils/domain";
import QuoteCard from "./QuoteCard";
import { Download } from "lucide-react";
import jsPDF from "jspdf";

interface QuoteLayoutProps {
  quote: QuoteFlat;
}

const QuoteLayout: React.FC<QuoteLayoutProps> = ({ quote }) => {
  const hasCouple = quote.couple === "1";
  const hasChildren = quote.sons >= "1";

  const generatePDF = () => {
    const doc = new jsPDF();

    const addField = (label: string, value: string | null, y: number) => {
      if (!value) return y; // Skip if no value

      doc.setTextColor(107, 114, 128);
      doc.setFontSize(10);
      doc.text(label, 20, y);

      doc.setTextColor(0, 0, 0);
      doc.setFontSize(12);
      const lines = doc.splitTextToSize(value, 170);
      doc.text(lines, 20, y + 5);

      return y + lines.length * 7 + 8;
    };

    const addSection = (title: string, y: number) => {
      doc.setFontSize(14);
      doc.setTextColor(59, 130, 246);
      doc.text(title, 20, y);
      return y + 10;
    };

    // Datos personales
    doc.setFontSize(16);
    doc.setTextColor(59, 130, 246);
    doc.text("Datos de cotización", 20, 20);

    let y = 40;

    // Información personal
    y = addField("Nombre completo", quote.firstname, y);
    y = addField("Edad", `${quote.age} años`, y);
    y = addField(
      "Situación laboral",
      employTypes.find((employ) => employ.value === +quote.employ)?.label || "",
      y
    );
    y = addField("Zona", zoneTypes.find((zone) => zone.value === +quote.zone)?.label || "", y);

    // Contacto
    y += 5;
    doc.line(20, y, 190, y);
    y += 10;

    y = addField("Correo electrónico", quote.email, y);
    y = addField("Celular", `+54 ${quote.phone}`, y);

    // Información familiar
    y += 5;
    doc.line(20, y, 190, y);
    y += 10;

    if (hasCouple) {
      y = addField("Pareja", `Edad: ${quote.coupleage} años`, y);
    }

    if (hasChildren) {
      y = addField("Hijos/as", `Cantidad: ${quote.sons}`, y);
      if (quote.childrenage) {
        y = addField("Edades", quote.childrenage.split(";").join(", "), y);
      }
    }

    // Planes
    if (quote.plans && quote.plans.length > 0) {
      quote.plans.forEach((plan, index) => {
        doc.addPage();
        y = 20;

        // Encabezado del plan
        doc.setFontSize(16);
        doc.setTextColor(59, 130, 246);
        doc.text(`Plan ${index + 1}: ${plan.nameplan}`, 20, y);
        y += 20;

        // Información básica
        y = addSection("Información general", y);
        y = addField("Prestador", plan.nameprovider, y);
        y = addField("Plan base", plan.baseplan, y);
        y = addField("Nombre comercial", plan.tradename, y);

        // Cobertura principal
        y += 10;
        y = addSection("Cobertura principal", y);
        y = addField("Cobertura", plan.coverage, y);
        y = addField("Copago", plan.copayment, y);
        y = addField("Internación", plan.hospitalization, y);
        y = addField("Guardia", plan.emergency_room, y);

        // Servicios médicos
        y += 10;
        y = addSection("Servicios médicos", y);
        y = addField("Consultas en consultorio", plan.office_visit, y);
        y = addField("Visitas domiciliarias", plan.home_visits, y);
        y = addField("Telemedicina", plan.telemedicine, y);
        y = addField("Diagnóstico por imágenes", plan.diagnostic_imaging, y);
        y = addField("Laboratorio", plan.laboratory, y);

        // Especialidades
        y += 10;
        y = addSection("Especialidades", y);
        y = addField("Odontología", plan.dentistry, y);
        y = addField("Ortodoncia", plan.orthodontics, y);
        y = addField("Kinesiología", plan.physical_therapy, y);
        y = addField("Psicología", plan.psychology, y);
        y = addField("Oftalmología", plan.ophthalmology, y);

        // Beneficios adicionales
        y += 10;
        y = addSection("Beneficios adicionales", y);
        y = addField("Medicamentos ambulatorios", plan.outpatient_medications, y);
        y = addField("Prótesis", plan.prosthesis, y);
        y = addField("PMI", plan.PMI, y);
        y = addField("Óptica", plan.optics, y);
        y = addField("Cirugía estética", plan.cosmetic_surgery, y);
        y = addField("Placa de bruxismo", plan.bruxism_guard, y);
        y = addField("RPG", plan.rpg, y);

        // Comentarios
        if (plan.comments) {
          y += 10;
          y = addSection("Observaciones", y);
          y = addField("", plan.comments, y);
        }
      });
    }

    // Guardar el PDF
    doc.save(`cotizacion-${quote.firstname.toLowerCase().replace(/\s+/g, "-")}.pdf`);
  };

  return (
    <div className="w-full">
      {/* Fila 1: Nombre y Edad */}
      <div className="flex flex-col gap-4 sm:flex-row sm:items-start sm:gap-8">
        <div className="min-w-0 flex-1">
          <p className="mb-1 text-sm text-gray-500">Nombre completo</p>
          <p className="truncate text-base font-medium">{quote.firstname}</p>
        </div>

        <div className="shrink-0 sm:w-32">
          <p className="mb-1 text-sm text-gray-500">Edad</p>
          <p className="text-base font-medium">{quote.age} años</p>
        </div>
      </div>

      {/* Fila 2: Situación laboral y Zona */}
      <div className="flex-co4 mt-2 flex gap-4 sm:flex-row sm:items-start sm:gap-8">
        <div className="min-w-0 flex-1">
          <p className="mb-1 text-sm text-gray-500">Situación laboral</p>
          <p className="truncate text-base font-medium">
            {employTypes.find((employ) => employ.value === +quote.employ)?.label}
          </p>
        </div>

        <div className="shrink-0 sm:w-32">
          <p className="mb-1 text-sm text-gray-500">Zona</p>
          <p className="text-base font-medium">
            {zoneTypes.find((zone) => zone.value === +quote.zone)?.label}
          </p>
        </div>
      </div>

      {/* Fila 3: Email y Celular */}
      <div className="flex flex-col gap-4 border-t pt-4 sm:flex-row sm:items-start sm:gap-8">
        <div className="min-w-0 flex-1">
          <p className="mb-1 text-sm text-gray-500">Correo electrónico</p>
          <p className="truncate text-base font-medium">{quote.email}</p>
        </div>

        <div className="shrink-0">
          <p className="mb-1 text-sm text-gray-500">Celular</p>
          <p className="text-base font-medium">+54 {quote.phone}</p>
        </div>
      </div>

      {/* Fila 4: Datos Familiares */}
      <div className="grid grid-cols-1 gap-4 border-t pt-4 sm:grid-cols-2">
        <div>
          <div className="mb-1 flex items-center gap-2">
            <span
              className={`h-2 w-2 rounded-full ${hasCouple ? "bg-green-500" : "bg-gray-300"}`}
            ></span>
            <p className="text-sm text-gray-500">Incluir pareja</p>
          </div>
          {hasCouple && <p className="pl-4 text-base font-medium">Edad: {quote.coupleage} años</p>}
        </div>

        <div>
          <div className="mb-1 flex items-center gap-2">
            <span
              className={`h-2 w-2 rounded-full ${hasChildren ? "bg-green-500" : "bg-gray-300"}`}
            ></span>
            <p className="text-sm text-gray-500">
              Incluir hijos/as
              {hasChildren && ` (${quote.sons})`}
            </p>
          </div>
          {hasChildren && quote.childrenage && (
            <p className="pl-4 text-base font-medium">
              Edades:
              {" " + quote.childrenage.split(";").join(", ")}
            </p>
          )}
        </div>
      </div>

      {/* Fila 5: Comentarios */}
      <div className="grid grid-cols-1 gap-4 py-4 md:grid-cols-3 xl:gap-x-2">
        {quote.plans.map((plan, index) => (
          <QuoteCard key={index} quote={plan} isInteractive={false} isInHeader={false} />
        ))}
      </div>
      <div className="mr-2 flex justify-end">
        <Button size="xs" onClick={generatePDF}>
          <Download className="h-4 w-4" />
          Descargar en PDF
        </Button>
      </div>
    </div>
  );
};

export default QuoteLayout;
