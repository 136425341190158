import { useCallback, useState } from "react";
import { ConfirmDialog } from "./ConfirmModal";
import { Button } from "./Button";
import ExportarIcon from "../icons/exportar";
import toast from "react-hot-toast";

interface ExportButtonProps {
  onExport: () => void;
  exportText?: string;
  confirmText?: string;
  cancelText?: string;
  description?: string;
}

export const ExportButton = ({
  onExport,
  exportText = "Exportar",
  confirmText = "Confirmar",
  cancelText = "Cancelar",
  description = "Se exportará toda la información.",
}: ExportButtonProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleExportClick = useCallback(() => {
    setIsDialogOpen(true);
  }, []);

  const handleConfirm = useCallback(() => {
    onExport();
    setIsDialogOpen(false);
    toast.success("Se completó la descarga con éxito");
  }, [onExport]);

  const handleCancel = useCallback(() => {
    setIsDialogOpen(false);
  }, []);

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        intent="secondaryOutlined"
        leftIcon={ExportarIcon}
        onClick={handleExportClick}
        size="xs"
      >
        {exportText}
      </Button>

      <ConfirmDialog
        isOpen={isDialogOpen}
        onClose={handleCancel}
        onConfirm={handleConfirm}
        title="¿Deseas exportar toda la información?"
        description={description}
        confirmText={confirmText}
        cancelText={cancelText}
      />
    </>
  );
};
