import { useFormContext } from "react-hook-form";
import { SalesFilters } from "../types/filters";
import { PrepagasSelect } from "../../common/components/PrepagasSelect";
import { DateRangeFilter } from "../../common/components/DateRangeFilter";
import { VendedorFilter } from "../../common/components/VendedorFilter";
import { SaleStatus } from "../types/sales";
import { StatusFilter } from "../../common/components/StatusFilter";
import { CuotaRangeFilter } from "./CuotaRangeFilter";

interface FiltersProps {
  vendedores: string[];
}

export function Filters({ vendedores }: FiltersProps) {
  const { watch, setValue } = useFormContext<SalesFilters>();
  const selectedProviders = watch("prepaga") || [];
  const selectedStatus = watch("estado") as SaleStatus | undefined;
  const selectedSeller = watch("vendedor");

  const handleProvidersChange = (providers: string[]) => {
    setValue("prepaga", providers);
  };

  const handleStatusChange = (status: SaleStatus | undefined) => {
    setValue("estado", status);
  };

  const handleSellerChange = (seller: string | undefined) => {
    setValue("vendedor", seller);
  };

  return (
    <>
      <PrepagasSelect
        className="min-w-[100px] py-2"
        selectedProviders={selectedProviders}
        onProvidersChange={handleProvidersChange}
      />
      <StatusFilter className="py-2" value={selectedStatus} onValueChange={handleStatusChange} />
      <VendedorFilter
        className="py-2"
        value={selectedSeller}
        onValueChange={handleSellerChange}
        vendedores={vendedores}
      />
      <DateRangeFilter />
      <CuotaRangeFilter />
    </>
  );
}

export default Filters;
