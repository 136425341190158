import React, { useState } from "react";
import ClientesForm from "../ClientesForm/ClientesForm";
import CotizadorCotizaciones from "../CotizadorCotizaciones/CotizadorCotizaciones";
import CotizadorEstado from "../CotizadorEstado/CotizadorEstado";
import CustomModal from "../Modal/CustomModal";
import QuotationPage from "../../quotes/QuotationPage";
import MyQuotationsPage from "../../quotes/MyQuotationsPage";
import "./CotizadorViewContainer.css"; // Asumiendo que aún tienes este archivo CSS

const CotizadorViewContainer = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [client, setClient] = useState(null);

  return (
    <div className="cotizadorViewContainer bg-gray-100">
      {client !== null && (
        <>
          <CustomModal open={open} setOpen={setOpen} body={<ClientesForm client={client} />} />
          <CustomModal
            open={open2}
            setOpen={setOpen2}
            header={<p>Estado de contacto client Nº {client.id}</p>}
            body={<CotizadorEstado setOpen={setOpen2} />}
          />
        </>
      )}

      <div className="sliderBarContainer">
        <div className="sliderBar">
          <div className="sliderBarItem">
            <p onClick={() => setActiveTab(0)} className={activeTab === 0 ? "selected" : ""}>
              Mi cotizador
            </p>
          </div>
          <div className="sliderBarItem">
            <p onClick={() => setActiveTab(1)} className={activeTab === 1 ? "selected" : ""}>
              Mis cotizaciones
            </p>
          </div>
        </div>
      </div>

      <div className="sliderContainer" style={{ flexGrow: "1" }}>
        {activeTab === 0 ? (
          <div className="sliderItem firstLevelItem">
            <QuotationPage />
          </div>
        ) : (
          <div className="sliderItem firstLevelItem">
            <MyQuotationsPage />
          </div>
        )}
      </div>
    </div>
  );
};

export default CotizadorViewContainer;
