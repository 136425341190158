import { useContext, useState } from "react";
import { UserGlobalContext } from "../context/UserContex";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const API_URL = "https://bffdev.blinksalud.com/api/v1/login";

const useAuth = (initialValues = { email: "", password: "" }) => {
  const navigate = useNavigate();
  const { setUser } = useContext(UserGlobalContext);

  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({
    email: false,
    password: false,
  });
  const [loading, setLoading] = useState(false);

  const validateEmail = (email) => EMAIL_REGEX.test(email);

  const validateField = (name, value) => {
    if (name === "email") {
      return !value || !validateEmail(value);
    }
    return !value;
  };

  const handleChange = ({ target: { name, value } }) => {
    setErrors((prev) => ({
      ...prev,
      [name]: validateField(name, value),
    }));

    setValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleBlur = ({ target: { name, value } }) => {
    if (!value) {
      setErrors((prev) => ({
        ...prev,
        [name]: true,
      }));
    }
  };

  const saveUserAndNavigate = (userData) => {
    const enrichedUser = { ...userData, status: 2 };
    setUser(enrichedUser);
    localStorage.setItem("user", JSON.stringify(enrichedUser));
    navigate("/home");
  };

  const showError = (
    message = "Ocurrió un error con el servidor",
    detail = "Intente más tarde"
  ) => {
    Swal.fire({
      icon: "error",
      title: message,
      text: detail,
    });
  };

  const validateForm = () => {
    const newErrors = {
      email: validateField("email", values.email),
      password: validateField("password", values.password),
    };

    setErrors(newErrors);
    return !Object.values(newErrors).some(Boolean);
  };

  const login = async () => {
    try {
      const formData = new FormData();
      formData.append("email", values.email);
      formData.append("password", values.password);

      const response = await fetch(API_URL, {
        method: "POST",
        body: formData,
      });

      const data = await response.json();

      if (data.Status === 401) {
        throw new Error("INVALID_CREDENTIALS");
      }

      if (!response.ok) {
        throw new Error("SERVER_ERROR");
      }

      if (!data?.id || !data?.email) {
        throw new Error("INVALID_RESPONSE");
      }

      saveUserAndNavigate(data);
    } catch (error) {
      const errorMessages = {
        INVALID_CREDENTIALS: "E-mail o contraseña incorrectos",
        INVALID_RESPONSE: "Respuesta inválida del servidor",
        SERVER_ERROR: "Error del servidor",
      };

      showError(errorMessages[error.message] || "Error desconocido");
    }
  };

  const handleSubmit = () => {
    if (validateForm()) {
      setLoading(true);
      login().finally(() => setLoading(false));
    }
  };

  return {
    values,
    errors,
    loading,
    handleChange,
    handleBlur,
    handleSubmit,
  };
};

export default useAuth;
