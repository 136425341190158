import React from "react";

interface ReorderArrowUpIconProps {
  fill?: string;
  className?: string;
  width?: number;
  height?: number;
}

const ReorderArrowUpIcon = ({
  fill = "#4B5563",
  className = "",
  width = 24,
  height = 49,
}: ReorderArrowUpIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M14.0025 42.4962L20.5885 35.9102C21.3705 35.1282 22.6345 35.1282 23.4165 35.9102C24.1985 36.6922 24.1985 37.9562 23.4165 38.7382L14.8305 47.3242C14.0505 48.1042 13.0265 48.4942 12.0025 48.4942C10.9785 48.4942 9.95445 48.1042 9.17445 47.3242L0.588453 38.7382C-0.193547 37.9562 -0.193547 36.6922 0.588453 35.9102C1.37045 35.1282 2.63445 35.1282 3.41645 35.9102L10.0025 42.4962V2.49622C10.0025 1.39022 10.8965 0.496216 12.0025 0.496216C13.1085 0.496216 14.0025 1.39022 14.0025 2.49622V42.4962Z"
        fill={fill}
      />
    </svg>
  );
};

export default ReorderArrowUpIcon;
