import React from "react";
import * as Dialog from "@radix-ui/react-dialog";
import { X } from "lucide-react";
import { cn } from "../utils/cn";

interface ResponsiveDialogProps {
  trigger: React.ReactNode;
  children: React.ReactNode;
  isFullScreen?: boolean;
  title?: string;
}

const ResponsiveDialog = ({
  trigger,
  children,
  isFullScreen = false,
  title,
}: ResponsiveDialogProps) => {
  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>{trigger}</Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-black/50 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0" />
        <Dialog.Content
          className={cn(
            "fixed z-50 bg-gray-100 p-6 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95",
            isFullScreen
              ? "inset-0"
              : "left-1/2 top-1/2 w-full max-w-[1000px] -translate-x-1/2 -translate-y-1/2 rounded-lg"
          )}
        >
          <div className={cn("flex flex-col", isFullScreen ? "h-full w-full" : "max-h-[85vh]")}>
            <div className="flex items-center justify-between border-b pb-4">
              {title && <Dialog.Title className="text-lg font-semibold">{title}</Dialog.Title>}
              <Dialog.Close className="ml-auto rounded-full hover:bg-gray-100">
                <X className="h-6 w-6" />
              </Dialog.Close>
            </div>
            <div className={cn("flex-1 overflow-auto py-4")}>{children}</div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default ResponsiveDialog;
