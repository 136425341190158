import { format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";
import { DateRange } from "react-day-picker";
import { useFormContext } from "react-hook-form";
import { cn } from "../utils/cn";
import { Button } from "./Button";
import { Calendar } from "./Calendar";
import { Popover, PopoverContent, PopoverTrigger } from "./Popover";
import { useCallback, useState } from "react";
import { Input } from "./Input";

export function DateRangeFilter() {
  const { watch, setValue } = useFormContext();
  const dateRange = watch("vigencia");
  const [month, setMonth] = useState<Date>(dateRange?.from || new Date());

  const handleSelect = useCallback(
    (range: DateRange | undefined) => {
      setValue("vigencia", range);
    },
    [setValue]
  );

  const handleYearChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const year = parseInt(e.target.value);
      if (!isNaN(year)) {
        const newDate = new Date(month);
        newDate.setFullYear(year);
        setMonth(newDate);
      }
    },
    [month]
  );

  return (
    <div className="grid gap-2">
      <Popover>
        <PopoverTrigger asChild>
          <Button
            size="xs"
            id="date"
            variant="outlined"
            color="primary"
            intent="primaryBlock"
            className={cn(
              "min-w-[240px]",
              dateRange?.from || dateRange?.to ? "border-2 border-primary" : ""
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {dateRange?.from ? (
              dateRange.to ? (
                <>
                  {format(dateRange.from, "dd/MM/yyyy")} - {format(dateRange.to, "dd/MM/yyyy")}
                </>
              ) : (
                format(dateRange.from, "dd/MM/yyyy")
              )
            ) : (
              <span>Fecha de vigencia</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-4" align="end">
          <div className="mb-4">
            <Input
              type="number"
              value={month.getFullYear()}
              onChange={handleYearChange}
              className="w-[100px]"
              min={1900}
              max={2100}
            />
          </div>
          <Calendar
            mode="range"
            selected={dateRange}
            onSelect={handleSelect}
            month={month}
            onMonthChange={setMonth}
            numberOfMonths={2}
            defaultMonth={dateRange?.from}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}
