import { Dialog, DialogTitle, DialogContent, IconButton, TextareaAutosize } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Sale } from "../types/sales";
import { cva } from "class-variance-authority";
import { Comment as CommentType } from "../types/sales";
import { Button } from "../../common/components/Button";
import { useCallback, useState } from "react";
interface CommentDialogProps {
  open: boolean;
  onClose: () => void;
  sale: Sale | null;
}

export function CommentDialog({ open, onClose, sale }: CommentDialogProps) {
  const handleSubmit = useCallback((comment: string) => {
    console.log(comment);
  }, []);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" className="relative" fullWidth>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {sale && (
          <div className="font- absolute left-0 top-0 w-full bg-primary px-8 pb-4 pt-6 text-sm font-bold text-white">
            <p>Ticket N° {sale.venta}</p>
          </div>
        )}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {sale && (
          <div className="flex flex-col gap-4 px-0 py-6">
            {!sale.comentarios ? (
              <p className="py-4 text-center text-sm text-gray-600">
                No hay comentarios para esta venta
              </p>
            ) : (
              sale.comentarios?.map((comment, index) => <Comment key={index} comment={comment} />)
            )}
          </div>
        )}

        <CommentForm onSubmit={handleSubmit} />
      </DialogContent>
    </Dialog>
  );
}

const CommentVariants = cva("flex relative flex-col text- p-4 pb-8 group gap-2", {
  variants: {
    user: {
      admin: "items-start bg-primary/40  rounded-xl rounded-bl-none text-white",
      user: "items-end [&>h4]:text-primary",
    },
  },
});

// round all corners but the bottom left and right

export const Comment = ({ comment }: { comment: CommentType }) => {
  const { usuario, comentario, fecha } = comment;

  if (!usuario || !comentario) return null;

  return (
    <div className={CommentVariants({ user: usuario === "Administrador" ? "admin" : "user" })}>
      <h4 className="text-sm font-bold text-black/80">{usuario}</h4>
      <p className="text-sm text-black">{comentario}</p>
      <span className="absolute bottom-2 right-4 text-[0.65rem] text-black/80">{fecha}</span>
    </div>
  );
};

export const CommentForm = ({ onSubmit }: { onSubmit: (comment: string) => void }) => {
  const [comment, setComment] = useState("");

  return (
    <div className="flex flex-col gap-2">
      <p className="text-sm font-bold text-gray-500">Agregar observación/comentario</p>
      <TextareaAutosize
        placeholder="Escribe tu comentario aquí"
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        className="w-full rounded-md border-2 border-gray-300 px-2 pb-4 pt-2 text-sm"
      />
      <Button className="self-end" onClick={() => onSubmit(comment)} intent="primaryFlat">
        Enviar
      </Button>
    </div>
  );
};
