import { SVGProps } from "react";
const EyeOpenIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M47.0336 19.2499C43.9328 14.1141 36.8794 5.49756 24.5 5.49756C12.1206 5.49756 5.0672 14.1141 1.96635 19.2499C1.00776 20.8267 0.5 22.6438 0.5 24.4976C0.5 26.3513 1.00776 28.1684 1.96635 29.7452C5.0672 34.881 12.1206 43.4976 24.5 43.4976C36.8794 43.4976 43.9328 34.881 47.0336 29.7452C47.9922 28.1684 48.5 26.3513 48.5 24.4976C48.5 22.6438 47.9922 20.8267 47.0336 19.2499ZM43.6249 27.6164C40.9619 32.0203 34.9341 39.4312 24.5 39.4312C14.0659 39.4312 8.0381 32.0203 5.37509 27.6164C4.80557 26.6792 4.50391 25.5993 4.50391 24.4976C4.50391 23.3958 4.80557 22.3159 5.37509 21.3787C8.0381 16.9748 14.0659 9.56391 24.5 9.56391C34.9341 9.56391 40.9619 16.9667 43.6249 21.3787C44.1944 22.3159 44.4961 23.3958 44.4961 24.4976C44.4961 25.5993 44.1944 26.6792 43.6249 27.6164Z"
      fill="currentColor"
    />
    <path
      d="M24.5087 14.4976C22.5309 14.4976 20.5975 15.084 18.953 16.1829C17.3085 17.2817 16.0268 18.8435 15.2699 20.6707C14.5131 22.498 14.315 24.5087 14.7009 26.4485C15.0867 28.3883 16.0391 30.1701 17.4377 31.5686C18.8362 32.9672 20.618 33.9196 22.5578 34.3054C24.4976 34.6913 26.5083 34.4932 28.3356 33.7364C30.1628 32.9795 31.7246 31.6978 32.8234 30.0533C33.9222 28.4088 34.5087 26.4754 34.5087 24.4976C34.5056 21.8464 33.451 19.3047 31.5763 17.43C29.7016 15.5553 27.1599 14.5007 24.5087 14.4976ZM24.5087 30.4976C23.322 30.4976 22.162 30.1457 21.1753 29.4864C20.1886 28.8271 19.4196 27.89 18.9655 26.7937C18.5113 25.6973 18.3925 24.4909 18.624 23.327C18.8555 22.1631 19.427 21.094 20.2661 20.2549C21.1052 19.4158 22.1743 18.8444 23.3382 18.6128C24.5021 18.3813 25.7085 18.5002 26.8048 18.9543C27.9012 19.4084 28.8383 20.1774 29.4976 21.1641C30.1568 22.1508 30.5087 23.3109 30.5087 24.4976C30.5087 26.0889 29.8766 27.615 28.7514 28.7402C27.6262 29.8654 26.1 30.4976 24.5087 30.4976Z"
      fill="currentColor"
    />
  </svg>
);

export default EyeOpenIcon;
