import {
  ColumnFiltersState,
  PaginationState,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useState, useMemo } from "react";
import { Sale } from "../types/sales";
import { DEFAULT_PAGE_SIZE } from "../../common/constants/pagination";

interface UseTableConfigProps {
  data: Sale[];
  columns: any[];
  dataKeys: string[];
}

export function useTableConfig({ data, columns, dataKeys }: UseTableConfigProps) {
  const [globalFilter, setGlobalFilter] = useState("");
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    filterFns: {
      dateRange: (row, columnId, value: { from: Date; to: Date }) => {
        const rowDate = new Date(row.getValue(columnId));

        if (value.from && !value.to) {
          return rowDate >= value.from;
        }

        if (!value.from && value.to) {
          return rowDate <= value.to;
        }

        if (value.from && value.to) {
          return rowDate >= value.from && rowDate <= value.to;
        }

        return true;
      },
    },
    state: {
      columnVisibility: {
        id: false,
        comentarios: false,
      },
      columnOrder: [...dataKeys, "eye", "comment"],
      globalFilter,
      pagination,
      columnFilters,
    },
    onPaginationChange: setPagination,
    onGlobalFilterChange: setGlobalFilter,
    manualPagination: false,
  });

  return {
    table,
    globalFilter,
    setGlobalFilter,
    columnFilters,
    setColumnFilters,
    pagination,
    setPagination,
  };
}
