import { MutationOptions, useMutation, useQuery } from "@tanstack/react-query";
import { ApiError, callApi } from "../config/api";

export interface GetQuotePayload {
  firstname: string;
  email: string;
  phone: number;
  age: number;
  employ: number;
  couple: number;
  coupleage: number;
  netsalary: number;
  childrenage: string;
  sons: number;
  zone: number;
}

export interface Clinic {
  clinicId: number;
  clinicName: string;
  clinicDesc: string;
  zoneId: number;
  zone: string;
  zoneDesc: string;
  locality: string | null;
  department: string | null;
  province: string | null;
}

export interface GetQuoteResponse {
  planId: number;
  providerId: number;
  employId: number;
  code: string;
  nameplan: string;
  tradename: string;
  baseplan: string;
  coverage: string;
  copayment: string;
  emergency_room: string;
  hospitalization: string;
  dentistry: string;
  orthodontics: string;
  office_visit: string;
  prosthesis: string;
  PMI: string;
  optics: string;
  home_visits: string;
  diagnostic_imaging: string;
  laboratory: string;
  physical_therapy: string;
  psychology: string;
  ophthalmology: string;
  outpatient_medications: string;
  cosmetic_surgery: string;
  bruxism_guard: string | null;
  rpg: string | null;
  telemedicine: string | null;
  documentpath: string | null;
  comments: string;
  created_at: string;
  updated_at: string;
  enabled: number;
  deact_date: string | null;
  id: number;
  nameprovider: string;
  clinics: Clinic[];
  price: number;
}

interface User {
  id: number;
  token: string;
  email: string;
  firstname: string;
  status: number;
  surname: string;
}

export interface CreateQuotePayload {
  userId: number;
  firstname: string;
  email: string;
  phone: string;
  age: number;
  netsalary: number;
  couple: string;
  coupleage: number;
  sons: string;
  childrenage: string;
  zone: string;
  employ: string;
  plans: {
    nameprovider: string;
    nameplan: string;
    quotedvalue: number;
    cost: number;
  }[]

}

interface QuotePlan extends GetQuoteResponse {
  Id: number;
  providerId: number;
}

export interface QuoteFlat {
  quotesflatsId: number | null;
  userId: number;
  firstname: string;
  full_username: string;
  email: string;
  phone: string;
  age: number;
  childrenage: string;
  couple: string;
  coupleage: number;
  netsalary: string;
  employ: string;
  sons: string;
  zone: string;
  group_id: string;
  origin: string;
  toprocess: number;
  created_at: string;
  updated_at: string;
  plans: QuotePlan[];
}

interface GetListQuotations {
  exception: any;
  headers: any;
  original: {
    pagination: {
      currentPage: string;
      pageSize: string;
      totalPages: number;
      totalRecords: number;
    },
    quotesflats: QuoteFlat[];
  }

}

export function useGetQuoteQuery(payload: GetQuotePayload | null) {
  const user = localStorage.getItem("user");
  const token = user ? JSON.parse(user).token : null;

  return useQuery<any, ApiError>({
    queryKey: [payload],
    queryFn: () => {
      const formData = new FormData();
      if (payload) {
        Object.entries(payload).forEach(([key, value]) => {
          formData.append(key, value);
        });
      }

      return callApi<GetQuoteResponse[]>("/quote", {
        method: "POST",
        body: formData,
        headers: {
          Authorization: token,
        },
        isMultipart: true,
      }).then(({ data }) => data);
    },
    enabled: !!payload,
  });
}


export function useCreateQuoteMutation(options: MutationOptions<void, ApiError, CreateQuotePayload>) {
  const user = localStorage.getItem("user");
  const token = user ? JSON.parse(user).token : null;

  return useMutation<void, ApiError, CreateQuotePayload>({
    mutationFn: (payload) => {
      return callApi("/quoter", {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          quotesflats: [{
            ...payload,
            origin: 'crm',
            toprocess: 0,
            Authorization: token
          }]
        })
      })
        .then(response => {
          return undefined;
        });
    },
    onSuccess: options.onSuccess,
    onError: options.onError,
  });
}

export function useGetListQuotationsQuery(
  pageNumber: number,
  pageSize: number
) {
  const user: User = JSON.parse(localStorage.getItem("user") ?? '{}');

  return useQuery<GetListQuotations, ApiError>({
    queryKey: [user?.id!, pageNumber, pageSize],
    queryFn: () => {
      return callApi<GetListQuotations>("/quotes", {
        method: "GET",
        params: {
          userId: user?.id,
          pageNumber,
          pageSize
        },
        headers: {
          Authorization: user?.token,
          "Accept": "*/*",
          "Accept-Encoding": "gzip, deflate, br",
          "Connection": "keep-alive",
          "Host": "bffdev.blinksalud.com",
          "User-Agent": "PostmanRuntime/7.43.0",
          "Content-Type": "application/json",
        }
      }).then(({ data }) => data);
    },
  });
}
