import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { format } from "date-fns";
import { useMemo } from "react";
import { Sale } from "../types/sales";
import { StatusSelect } from "../../common/components/StatusSelect";
import UserIcon from "../../common/icons/user";
import EyeOpenIcon from "../../common/icons/eye-open";
import CommentIcon from "../../common/icons/comment";
import { createBaseColumn } from "../../common/utils/create-base-column";

const columnHelper = createColumnHelper<Sale>();

interface UseTableColumnsProps {
  dataKeys: string[];
  onOpenDialog: (sale: Sale) => void;
}

export function useTableColumns({ dataKeys, onOpenDialog }: UseTableColumnsProps) {
  return useMemo(() => {
    const configs: Record<string, ColumnDef<Sale>> = {
      estado: {
        header: "Estado",
        cell: ({ row }) => <StatusSelect className="mx-auto" status={row.original.estado} />,
      },
      venta: {
        header: "N° Venta",
      },
      capitas: {
        header: "Capitas",
        cell: ({ row }) => (
          <span className="flex items-center justify-center gap-2">
            <UserIcon className="aspect-square h-6 text-primary" />
            {row.original.capitas}
          </span>
        ),
      },
      cuota: {
        header: "Cuota",
        filterFn: (row, columnId, value: { min?: number; max?: number }) => {
          const cuota = row.getValue<number>(columnId);
          if (!value.min && !value.max) return true;
          if (value.min && !value.max) return cuota >= value.min;
          if (!value.min && value.max) return cuota <= value.max;
          return cuota >= value.min! && cuota <= value.max!;
        },
        cell: ({ row }) => (
          <span className="flex items-center justify-center gap-2">
            ${row.original.cuota.toFixed(2)}
          </span>
        ),
      },
      vigencia: {
        header: "Vigencia",
        filterFn: (row, columnId, filterValue: { from?: Date; to?: Date }) => {
          const rowDate = new Date(row.getValue(columnId));
          if (!filterValue.from && !filterValue.to) return true;
          if (filterValue.from && !filterValue.to) return rowDate >= filterValue.from;
          if (!filterValue.from && filterValue.to) return rowDate <= filterValue.to;
          return rowDate >= filterValue.from! && rowDate <= filterValue.to!;
        },
        cell: ({ row }) => format(new Date(row.original.vigencia), "dd/MM/yyyy"),
      },
    };

    const columns: ColumnDef<Sale>[] = [
      columnHelper.display({
        id: "eye",
        cell: () => (
          <span className="mx-auto flex max-w-6 items-center justify-center">
            <EyeOpenIcon className="aspect-square h-6 text-primary" />
          </span>
        ),
      }),
      columnHelper.display({
        id: "comment",
        cell: ({ row }) => (
          <span
            className="mx-auto flex max-w-6 cursor-pointer items-center justify-center text-center"
            onClick={() => onOpenDialog(row.original)}
          >
            <CommentIcon className="aspect-square h-6 text-primary hover:text-primary/80" />
          </span>
        ),
      }),
    ];

    for (const key of dataKeys) {
      configs[key]
        ? columns.push(columnHelper.accessor(key as any, configs[key]))
        : columns.push(columnHelper.accessor(key as any, createBaseColumn(key)));
    }

    return columns;
  }, [dataKeys, onOpenDialog]);
}
