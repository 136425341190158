import { SVGProps } from "react";
const ExportarIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M40.502 22.4993V38.4993C40.502 44.0133 36.016 48.4993 30.502 48.4993H10.502C4.98795 48.4993 0.501953 44.0133 0.501953 38.4993V18.4993C0.501953 12.9853 4.98795 8.49927 10.502 8.49927H26.502C27.606 8.49927 28.502 9.39527 28.502 10.4993C28.502 11.6033 27.606 12.4993 26.502 12.4993H10.502C7.19395 12.4993 4.50195 15.1913 4.50195 18.4993V38.4993C4.50195 41.8073 7.19395 44.4993 10.502 44.4993H30.502C33.81 44.4993 36.502 41.8073 36.502 38.4993V22.4993C36.502 21.3953 37.398 20.4993 38.502 20.4993C39.606 20.4993 40.502 21.3953 40.502 22.4993ZM42.502 0.499268H28.502C27.398 0.499268 26.502 1.39527 26.502 2.49927C26.502 3.60327 27.398 4.49927 28.502 4.49927H41.674L17.088 29.0853C16.306 29.8673 16.306 31.1313 17.088 31.9133C17.478 32.3033 17.99 32.4993 18.502 32.4993C19.014 32.4993 19.526 32.3033 19.916 31.9133L44.502 7.32727V20.4993C44.502 21.6033 45.398 22.4993 46.502 22.4993C47.606 22.4993 48.502 21.6033 48.502 20.4993V6.49927C48.502 3.19127 45.81 0.499268 42.502 0.499268Z"
      fill="currentColor"
    />
  </svg>
);

export default ExportarIcon;
