import { cva, type VariantProps } from "class-variance-authority";

import * as React from "react";

import { cn } from "../utils/cn";

const buttonVariants = cva(
  `relative inline-flex items-center justify-center gap-2 truncate whitespace-nowrap transition-colors 
  active:hover:bg-[#23226b] hover:scale-105 hover:bg-primary hover:text-white transition-transform
  font-bold
  focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50`,
  {
    variants: {
      variant: {
        flat: `rounded border-none`,
        outlined: `rounded border-2 bg-transparent`,
      },
      size: {
        xl: `w-[330px] h-[56px] px-0 py-0 gap-4 rounded-[56px]`,
        md: `w-[170px] h-[56px] px-0 py-0 gap-4 rounded-[56px]`,
        sm: `w-[128px] h-[56px] px-0 py-0 gap-4 rounded-[56px]`,
        xs: `px-2 h-[40px] py-0 gap-2 rounded-[40px]`,
      },
      intent: {
        primaryFlat: `bg-[#34E8CA] text-gray-600`,
        disabledFlat: `bg-[#AEAEAE] text-white`,
        infoFlat: `bg-[#052E27] text-white`,
        secondaryFlat: `bg-[#0A5C4E] text-white`,
        primaryOutlined: `text-[#4744CC] border-[#4744CC]`,
        disabledOutlined: `text-[#AEAEAE] border-[#AEAEAE]`,
        infoOutlined: `text-[#06052E] border-[#06052E]`,
        secondaryOutlined: `text-[#120F8D] border-[#120F8D] hover:border-none`,
        primaryBlock: `bg-white border-[1px] rounded-sm hover:bg-gray-100 
          hover:border-black focus:bg-gray-100 active:border-primary 
          active:hover:bg-gray-100  focus:border-primary 
          font-normal hover:scale-1 hover:text-dark text-dark border-gray-300
          data-[state=open]:border-primary`,
      },
    },
    defaultVariants: {
      variant: "flat",
      intent: "primaryFlat",
      size: "md",
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  isLoading?: boolean;
  leftIcon?: React.ComponentType<{ className?: string }>;
  icon?: React.ComponentType<{ className?: string }>;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      intent,
      children,
      isLoading,
      disabled,
      icon: Icon,
      leftIcon: LeftIcon,
      ...props
    },
    ref
  ) => {
    return (
      <button
        type="button"
        className={cn(buttonVariants({ intent, variant, size, className }))}
        ref={ref}
        {...props}
        disabled={disabled || isLoading}
      >
        {LeftIcon && <LeftIcon className={cn("h-4 w-4", isLoading && "text-transparent")} />}
        {children}

        {Icon && <Icon className={cn("h-4 w-4", isLoading && "text-transparent")} />}
      </button>
    );
  }
);

Button.displayName = "Button";

export { Button, buttonVariants };
